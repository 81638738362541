import { CampaignTypeEnum } from './enum/campaign-type.enum';

export interface CampaignModel {
  readonly id: string;
  readonly createAt: string;
  readonly updatedAt: string;
  readonly organizationId: string;
  readonly accountId: string;
  readonly countingId: string;
  readonly countingVersionId: string;
  readonly createUserId: string;
  readonly updateUserId: string;
  readonly state: CampaignStateEnum;
  readonly name: string;
  readonly description?: string;
  readonly scheduleDate: string;
  readonly sms: SMSCampaignModel;
  readonly createUser: User;
  readonly updateUser: User;
  readonly type: CampaignTypeEnum;
  readonly profileImportId?: string;
}

export interface SMSCampaignModel {
  readonly unsubscribe?: string;
  readonly content: string;
  readonly url: string;
  readonly sender: string;
  readonly shortUrlDomain: string;
  readonly extraSendPercent?: number;
  readonly seedList?: string[];
}

export interface User {
  readonly displayName: string;
  readonly pictureUrl: string;
}

export enum CampaignStateEnum {
  Draft = 'draft',
  ValidationRequested = 'validation_requested',
  Ready = 'ready', // after launch
  Processing = 'processing', //  24H before scheduler
  Success = 'success', // scheduler
  Error = 'error',
}
