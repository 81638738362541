import { createFeatureSelector, createSelector } from '@ngrx/store';

import { RESOURCES_FEATURE_KEY, ResourcesState } from './resources.reducers';

export const selectResourcesState = createFeatureSelector<ResourcesState>(
  RESOURCES_FEATURE_KEY,
);
export const selectResourcesLoading = createSelector(
  selectResourcesState,
  (state: ResourcesState) => state.loading,
);
export const selectIABCategory = createSelector(
  selectResourcesState,
  (state: ResourcesState) => state.resources?.iabCategories ?? [],
);
export const selectDepartements = createSelector(
  selectResourcesState,
  (state: ResourcesState) => state.resources?.departments ?? [],
);
export const selectFilteredDepartements = createSelector(
  selectResourcesState,
  (state: ResourcesState) => state.resources?.filteredDepartments ?? [],
);
export const selectDistricts = createSelector(
  selectResourcesState,
  (state: ResourcesState) => state.resources?.districts ?? [],
);
export const selectCities = createSelector(
  selectResourcesState,
  (state: ResourcesState) => state.resources?.cities ?? [],
);
export const selectZipCodes = createSelector(
  selectResourcesState,
  (state: ResourcesState) => state.resources?.zipCode ?? [],
);
export const selectGender = createSelector(
  selectResourcesState,
  (state: ResourcesState) => state.resources?.genders ?? [],
);
export const selectAgeGroup5 = createSelector(
  selectResourcesState,
  (state: ResourcesState) => state.resources?.ageGroups5 ?? [],
);
export const selectFamilySquare = createSelector(
  selectResourcesState,
  (state: ResourcesState) => state.resources?.familySquares ?? [],
);
export const selectIncomeGroups = createSelector(
  selectResourcesState,
  (state: ResourcesState) => state.resources?.incomeGroups ?? [],
);
export const selectHousings = createSelector(
  selectResourcesState,
  (state: ResourcesState) => state.resources?.housings ?? [],
);
