import { LoadingStateEnum } from '@dmc-ng/data-access';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { campaignsActions } from './campaigns.actions';
import { CampaignModel } from '../models/campaign.model';

export const CAMPAIGNS_FEATURE_KEY = 'campaigns';

export const campaignsAdapter: EntityAdapter<CampaignModel> =
  createEntityAdapter<CampaignModel>();

export const campaignsSearchAdapter: EntityAdapter<CampaignModel> =
  createEntityAdapter<CampaignModel>({
    selectId: (campaign: CampaignModel) => campaign.id,
  });

export const paginatedCampaignAdapter: EntityAdapter<CampaignModel> =
  createEntityAdapter<CampaignModel>({
    selectId: (campaign: CampaignModel) => campaign.id,
  });

export interface CampaignsSearchState extends EntityState<CampaignModel> {
  loading: LoadingStateEnum;
  error?: unknown;
}

export interface PaginatedCampaignsState extends EntityState<CampaignModel> {
  loading: LoadingStateEnum;
  total?: number;
  totalInCurrentOffset?: number;
  error?: unknown;
}

export interface CampaignsState extends EntityState<CampaignModel> {
  campaignsSearch: CampaignsSearchState;
  paginatedCampaigns: PaginatedCampaignsState;
  error?: unknown;
  loading: LoadingStateEnum;
}

export const initialCampaignsState: CampaignsState =
  campaignsAdapter.getInitialState({
    campaignsSearch: campaignsSearchAdapter.getInitialState({
      loading: LoadingStateEnum.Ready,
    }),
    paginatedCampaigns: paginatedCampaignAdapter.getInitialState({
      loading: LoadingStateEnum.Ready,
    }),
    loading: LoadingStateEnum.Ready,
  });

const reducer = createReducer(
  initialCampaignsState,
  on(campaignsActions.getCampaignsWithQuery, (state) => ({
    ...state,
    campaignsSearch: {
      ...state.campaignsSearch,
      loading: LoadingStateEnum.Loading,
    },
  })),
  on(
    campaignsActions.campaignsWithQueryRetrievedSuccessfully,
    (state, { campaigns }) => ({
      ...state,
      campaignsSearch: {
        ...campaignsSearchAdapter.setAll(campaigns, state.campaignsSearch),
        loading: LoadingStateEnum.Done,
      },
    }),
  ),
  on(
    campaignsActions.campaignsWithQueryFailedToBeRetrieved,
    (state, { error }) => ({
      ...state,
      campaignsSearch: {
        ...state.campaignsSearch,
        loading: LoadingStateEnum.Done,
        error,
      },
    }),
  ),
  on(campaignsActions.clearFetchedCampaigns, (state) => ({
    ...state,
    campaignsSearch: {
      ...campaignsSearchAdapter.removeAll(state.campaignsSearch),
      loading: LoadingStateEnum.Done,
    },
  })),
  on(campaignsActions.getPaginatedCampaigns, (state: CampaignsState) => ({
    ...state,
    paginatedCampaigns: {
      ...state.paginatedCampaigns,
      loading: LoadingStateEnum.Loading,
    },
  })),
  on(
    campaignsActions.campaignsPaginatedRetrievedSuccessfully,
    (state, { campaigns, total, totalInCurrentOffset }) => ({
      ...campaignsAdapter.upsertMany(campaigns, {
        ...state,
        loading: LoadingStateEnum.Done,
      }),
      paginatedCampaigns: {
        ...paginatedCampaignAdapter.setAll(campaigns, state.paginatedCampaigns),
        total,
        totalInCurrentOffset,
        loading: LoadingStateEnum.Done,
      },
    }),
  ),
  on(
    campaignsActions.campaignsPaginatedFailedToBeRetrieved,
    (state, { error }) => ({
      ...state,
      paginatedCampaigns: {
        ...state.paginatedCampaigns,
        loading: LoadingStateEnum.Done,
        error,
      },
    }),
  ),
  on(
    campaignsActions.getOneCampaign,
    campaignsActions.createCampaign,
    campaignsActions.deleteCampaign,
    campaignsActions.updateCampaign,
    campaignsActions.launchCampaign,
    campaignsActions.validateCampaign,
    campaignsActions.revertToDraftCampaign,
    (state) => ({
      ...state,
      loading: LoadingStateEnum.Loading,
      error: undefined,
    }),
  ),
  on(
    campaignsActions.campaignRetrievedSuccessfully,
    campaignsActions.campaignCreatedSuccessfully,
    campaignsActions.campaignDeleteSuccessfully,
    campaignsActions.campaignUpdatedSuccessfully,
    campaignsActions.campaignLaunchedSuccessfully,
    campaignsActions.campaignValidatedSuccessfully,
    campaignsActions.campaignRevertedToDraftSuccessfully,
    (state) => ({
      ...state,
      loading: LoadingStateEnum.Done,
    }),
  ),
  on(
    campaignsActions.campaignFailedToBeRetrieved,
    campaignsActions.campaignFailedToBeCreated,
    campaignsActions.campaignFailedToBeDeleted,
    campaignsActions.campaignFailedToBeUpdated,
    campaignsActions.campaignFailedToBeLaunched,
    campaignsActions.campaignFailedToBeValidated,
    campaignsActions.campaignFailedToBeRevertedToDraft,
    (state, { error }) => ({
      ...state,
      error,
      loading: LoadingStateEnum.Done,
    }),
  ),
);

export function campaignsReducer(
  state: CampaignsState | undefined,
  action: Action,
): CampaignsState {
  return reducer(state, action);
}
