import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { APIResponse } from '@dmc-ng/data-access';
import { API_BASE_URL } from '@dmc-ng/data-access/injection-token';
import { Observable } from 'rxjs';

import { UserMeModel } from './models/user/user-me.model';

@Injectable()
export class MeService {
  private readonly apiBaseUrl = inject(API_BASE_URL);

  constructor(private readonly httpClient: HttpClient) {}

  loadMe(): Observable<APIResponse<UserMeModel>> {
    return this.httpClient.get<APIResponse<UserMeModel>>(`${this.apiBaseUrl}/me/`);
  }
}
