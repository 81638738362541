import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { UserMePolicyModel } from '../models/user/user-me.model';

export const meActions = createActionGroup({
  source: 'Me',
  events: {
    'Get Policies': emptyProps(),
    'Me Policies retrieved successfully': props<{
      policies: UserMePolicyModel[];
      superAdmin: boolean;
      role: string;
    }>(),
    'Me Policies failed to be retrieved': props<{ error: unknown }>(),
  },
});
