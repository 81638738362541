import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AvatarComponent } from '@dmc-ng/ui/avatar';
import {
  IconColorEnum,
  IconComponent,
  IconSizeEnum,
  IconThemeEnum,
} from '@dmc-ng/ui/icon';
import {
  ImageCroppedEvent,
  ImageCropperModule,
  OutputFormat,
} from 'ngx-image-cropper';

import { UploadLogoDialogModel } from '../../models/upload-logo-dialog.model';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

@Component({
  selector: 'dmc-ng-upload-logo-dialog',
  templateUrl: './upload-logo-dialog.component.html',
  styleUrls: ['./upload-logo-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    ImageCropperModule,
    MatProgressSpinnerModule,
    AvatarComponent,
    IconComponent,
    BaseDialogComponent,
  ],
  standalone: true,
})
export class UploadLogoDialogComponent {
  readonly iconSizeEnum: typeof IconSizeEnum = IconSizeEnum;
  readonly iconThemeEnum: typeof IconThemeEnum = IconThemeEnum;
  readonly iconColorEnum: typeof IconColorEnum = IconColorEnum;

  IMAGE_SIZE = 180;
  IMAGE_FORMAT: OutputFormat = 'png';
  IMAGE_OUTPUT: 'blob' | 'base64' = 'base64';

  croppedImage?: string | null = null;
  imageToUpload!: string;

  imageState = signal<'loading' | 'load' | 'failed'>('loading');

  constructor(@Inject(MAT_DIALOG_DATA) public data: UploadLogoDialogModel) {}

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
    if (this.croppedImage) {
      const base64Index =
        this.croppedImage.indexOf(';base64,') + ';base64,'.length;
      this.imageToUpload = this.croppedImage.substring(base64Index);
    }
  }

  imageLoaded(): void {
    this.imageState.set('load');
  }

  loadImageFailed(): void {
    this.imageState.set('failed');
  }
}
