import { APIResponse, DeepPartial } from '@dmc-ng/data-access';
import { SortModel } from '@dmc-ng/utils';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { BatModel } from '../models/bat.model';
import { CampaignModel, CampaignStateEnum } from '../models/campaign.model';
import { CampaignsMetricsOverviewModel } from '../models/campaigns-metrics-overview.model';
import { TypeActionsEnum } from '../models/enum/type-actions.enum';

export const campaignsActions = createActionGroup({
  source: 'Campaigns',
  events: {
    'Get campaigns with query': props<{
      organizationId: string;
      accountId: string;
      query: string;
      limit?: number;
      status?: CampaignStateEnum[];
    }>(),
    'Campaigns with query retrieved successfully': props<{
      campaigns: CampaignModel[];
    }>(),
    'Campaigns with query failed to be retrieved': props<{
      error: unknown;
    }>(),
    'Clear fetched campaigns': emptyProps(),

    'Get paginated Campaigns': props<{
      organizationId: string;
      accountId: string;
      offset?: number;
      limit?: number;
      status?: CampaignStateEnum[];
      sort?: SortModel;
    }>(),
    'Campaigns paginated retrieved successfully': props<{
      campaigns: CampaignModel[];
      total: number;
      totalInCurrentOffset: number;
    }>(),
    'Campaigns paginated failed to be retrieved': props<{ error: unknown }>(),

    'Get one Campaign': props<{
      organizationId: string;
      accountId: string;
      campaignId: string;
    }>(),
    'Campaign retrieved successfully': props<{
      campaign: APIResponse<CampaignModel>;
    }>(),
    'Campaign failed to be retrieved': props<{ error: unknown }>(),

    'Create Campaign': props<{
      organizationId: string;
      accountId: string;
      campaign: DeepPartial<CampaignModel>;
      typeAction?: TypeActionsEnum;
    }>(),
    'Campaign created successfully': props<{
      campaign: APIResponse<CampaignModel>;
      typeAction?: TypeActionsEnum;
    }>(),
    'Campaign failed to be created': props<{ error: unknown }>(),

    'Update Campaign': props<{
      organizationId: string;
      accountId: string;
      campaignId: string;
      campaign: DeepPartial<CampaignModel>;
      typeAction?: TypeActionsEnum;
    }>(),
    'Campaign updated successfully': props<{
      campaign: APIResponse<CampaignModel>;
      typeAction?: TypeActionsEnum;
    }>(),
    'Campaign failed to be updated': props<{ error: unknown }>(),

    'Delete Campaign': props<{
      organizationId: string;
      accountId: string;
      campaignId: string;
      offset?: number;
      status?: CampaignStateEnum[];
    }>(),
    'Campaign delete successfully': props<{
      campaignId: string;
      offset?: number;
      status?: CampaignStateEnum[];
    }>(),
    'Campaign failed to be deleted': props<{ error: unknown }>(),

    'Launch Campaign': props<{
      organizationId: string;
      accountId: string;
      campaignId: string;
      afterCreateOrUpdate: boolean;
    }>(),
    'Campaign launched successfully': props<{
      campaignId: string;
      afterCreateOrUpdate: boolean;
    }>(),
    'Campaign failed to be launched': props<{ error: unknown }>(),

    'Validate Campaign': props<{
      organizationId: string;
      accountId: string;
      campaignId: string;
      afterCreateOrUpdate: boolean;
    }>(),
    'Campaign validated successfully': props<{
      campaignId: string;
      afterCreateOrUpdate: boolean;
    }>(),
    'Campaign failed to be validated': props<{ error: unknown }>(),

    'Revert to draft Campaign': props<{
      organizationId: string;
      accountId: string;
      campaignId: string;
    }>(),
    'Campaign reverted to draft successfully': emptyProps(),
    'Campaign failed to be reverted to draft': props<{ error: unknown }>(),

    'Launch Bat': props<{
      organizationId: string;
      accountId: string;
      bat: BatModel;
    }>(),
    'Bat launched successfully': emptyProps(),
    'Bat failed to be launched': props<{ error: unknown }>(),

    'Get campaigns metrics': props<{
      organizationId: string;
      accountId: string;
      startDate: string;
      endDate: string;
    }>(),
    'Campaigns metrics retrieved successfully': props<{
      metrics: CampaignsMetricsOverviewModel;
    }>(),
    'Campaigns metrics failed to be retrieved': props<{
      error: unknown;
    }>(),
  },
});
