import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { LogoListComponent } from '@dmc-ng/ui/logo-list';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

interface LogoListParams extends ICellRendererParams {
  logoListInfo: { name: string; logo?: string }[];
}

@Component({
  standalone: true,
  imports: [CommonModule, LogoListComponent],
  templateUrl: './logo-list.component.html',
  styleUrl: './logo-list.component.scss',
})
export class LogoListCellRendererComponent implements ICellRendererAngularComp {
  logoListInfo: { name: string; logo?: string }[] = [];

  agInit(params: LogoListParams): void {
    this.logoListInfo = params.logoListInfo;
  }

  refresh(params: LogoListParams): boolean {
    this.logoListInfo = params.logoListInfo;
    return true;
  }
}
