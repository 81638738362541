import { DestroyRef, Inject, Injectable, InjectionToken } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { dmcConsoleActions } from '../state/dmc-console.actions';
import { isFormModified } from '../utils/reactive-form.utils';

export const FORM_ID = new InjectionToken<string>('FORM_ID');

@Injectable({
  providedIn: 'root',
})
export class FormStateService {
  private initialFormValues$ = new BehaviorSubject<any>(null);
  private forcedCurrentFormValues$ = new BehaviorSubject<any>(null);

  constructor(
    private store: Store,
    @Inject(FORM_ID) private formId: string,
  ) {}

  initFormState<T>(
    form: FormGroup | FormControl,
    destroyRef: DestroyRef,
  ): void {
    this.setInitialFormValues<T>(form.getRawValue());
    this.monitorFormChanges(form, destroyRef);
  }

  setInitialFormValues<T>(values: T): void {
    this.initialFormValues$.next(values);
  }

  setForcedCurrentFormValue<T>(values: T): void {
    this.forcedCurrentFormValues$.next(values);
  }

  private removeFormIdFromStore(): void {
    this.store.dispatch(
      dmcConsoleActions.removeFormFromDirtyState({ formId: this.formId }),
    );
  }

  private monitorFormChanges<T>(
    form: FormGroup | FormControl,
    destroyRef: DestroyRef,
  ): void {
    combineLatest([
      this.initialFormValues$,
      form.valueChanges,
      this.forcedCurrentFormValues$,
    ])
      .pipe(
        takeUntilDestroyed(destroyRef),
        finalize(() => this.removeFormIdFromStore()),
      )
      .subscribe(
        ([initialFormValues, currentValues, forcedCurrentFormValues]) => {
          const isFormDirty = isFormModified<T>(
            initialFormValues,
            forcedCurrentFormValues ?? currentValues,
          );

          this.store.dispatch(
            dmcConsoleActions.setFormDirtyState({
              formId: this.formId,
              isDirty: isFormDirty,
            }),
          );
        },
      );
  }
}
