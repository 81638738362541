import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  ORGANIZATIONS_FEATURE_KEY,
  organizationsAdapter,
  OrganizationsState,
  paginatedOrganizationAdapter,
} from './organizations.reducers';
import { OrganizationsUtils } from '../utils/organizations.utils';

export const selectOrganizationsState =
  createFeatureSelector<OrganizationsState>(ORGANIZATIONS_FEATURE_KEY);
const { selectEntities, selectAll } = organizationsAdapter.getSelectors();
const {
  selectEntities: paginatedOrganizationsEntities,
  selectAll: paginatedOrganizations,
} = paginatedOrganizationAdapter.getSelectors();

export const selectOrganizationsEntities = createSelector(
  selectOrganizationsState,
  selectEntities,
);

export const selectOrganizations = createSelector(
  selectOrganizationsState,
  selectAll,
);

export const loadingOrganizations = createSelector(
  selectOrganizationsState,
  (state) => state.loading,
);

export const loadingOneOrganization = createSelector(
  selectOrganizationsState,
  (state) => state.loadingOneOrganization,
);

export const selectOrganizationForInputSelect = createSelector(
  selectOrganizations,
  (organizations) => OrganizationsUtils.createInputSelectData(organizations),
);

export const selectPaginatedOrganizationsState = createSelector(
  selectOrganizationsState,
  (state) => state.paginatedOrganizations,
);
export const selectPaginatedOrganizationsEntities = createSelector(
  selectPaginatedOrganizationsState,
  paginatedOrganizationsEntities,
);
export const selectPaginatedOrganizations = createSelector(
  selectPaginatedOrganizationsState,
  paginatedOrganizations,
);
export const selectPaginatedOrganizationsError = createSelector(
  selectPaginatedOrganizationsState,
  (state) => state.error,
);
export const selectPaginatedOrganizationsnLoading = createSelector(
  selectPaginatedOrganizationsState,
  (state) => state.loading,
);
export const selectPaginatedOrganizationsTotal = createSelector(
  selectPaginatedOrganizationsState,
  (state) => state.total,
);

export const selectPaginatedOrganizationsTotalInCurrentOffset = createSelector(
  selectPaginatedOrganizationsState,
  (state) => state.totalInCurrentOffset,
);

export const selectOrganization = (id: string) =>
  createSelector(
    selectOrganizationsState,
    (organizationState) => organizationState.entities[id],
  );

export const selectAccountOrganizationLogo = (
  organizationId: string,
  accountId: string,
) =>
  createSelector(
    selectOrganizationsState,
    (organizationState) =>
      organizationState.entities[organizationId]?.accounts?.find(
        (account) => account.id === accountId,
      )?.logoUrl,
  );

export const selectAccountOrganizationName = (
  organizationId: string,
  accountId: string,
) =>
  createSelector(
    selectOrganizationsState,
    (organizationState) =>
      organizationState.entities[organizationId]?.accounts?.find(
        (account) => account.id === accountId,
      )?.name,
  );
