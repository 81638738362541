import { SortModel } from './models/sort.model';

export class SortUtils {
  static getColumnSort(event: any): { colId: string; sort: string }[] {
    return event.columns
      .filter((col: any) => col.sort !== undefined)
      .map((col: any) => ({
        colId: col.getColId(),
        sort: col.getSort(),
      }));
  }

  static createQueryParams(col: SortModel): string {
    return (col.sort === 'asc' ? '+' : '-') + col.col;
  }

  static splitQueryParamsToSort(query: string): SortModel {
    return {
      col: query.slice(1),
      sort: query.startsWith('+') ? 'asc' : 'desc',
    };
  }
}
