import { DateTime } from 'luxon';

export class DateUtils {
  static combineDateAndTime(dateString: string, timeString: string): string {
    const dateISO = new Date(dateString).toISOString();
    const dateTime = DateTime.fromISO(dateISO);
    if (!dateTime.isValid) {
      throw new Error('Invalid date string');
    }
    const hours = new Date(timeString).getHours();
    const minutes = new Date(timeString).getMinutes();
    const combinedDateTime = dateTime.set({ hour: hours, minute: minutes });
    if (!combinedDateTime.isValid) {
      throw new Error('Invalid combined date and time');
    }
    return combinedDateTime.toISO();
  }

  static getLocalTimeFromISOString(dateString: string): string {
    const dateTime = DateTime.fromISO(dateString);
    return dateTime.toFormat('HH:mm');
  }
}
