import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sendingFees',
  standalone: true,
})
export class SendingFeesPipe implements PipeTransform {
  transform(count: number, cpm: number, currency: string): string | undefined {
    if (count !== 0) {
      const price = (count / 1000) * cpm;

      const userLocale = navigator.language || 'en-US';

      const formattedPrice = new Intl.NumberFormat(userLocale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(price);

      switch (currency) {
        case 'EUR':
          return `${formattedPrice} €`;

        default:
          return `${formattedPrice} ${currency}`;
      }
    }
    return '-';
  }
}
