import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { APIResponse } from '@dmc-ng/data-access';
import { API_BASE_URL } from '@dmc-ng/data-access/injection-token';
import { Observable } from 'rxjs';

import { PostResourcesRequest, ResourceModel } from './models/resource.model';

@Injectable()
export class ResourcesService {
  private readonly apiBaseUrl = inject(API_BASE_URL);

  constructor(private readonly httpClient: HttpClient) {}

  getResources(
    resources?: string[],
    query?: string,
    limit?: number,
  ): Observable<APIResponse<ResourceModel>> {
    let params = new HttpParams();
    if (resources) {
      params = params.append('types', resources.toString());
    }
    if (query) {
      params = params.append('q', query);
    }
    if (limit) {
      params = params.append('limit', limit);
    }

    return this.httpClient.get<APIResponse<ResourceModel>>(
      `${this.apiBaseUrl}/resources/`,
      { params },
    );
  }

  getDistrictsGeoJson(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.apiBaseUrl}/resources/districts.geojson`,
    );
  }

  getDepartmentsGeoJson(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.apiBaseUrl}/resources/departments.geojson`,
    );
  }

  postResouces(
    type: string,
    request: PostResourcesRequest,
  ): Observable<APIResponse<ResourceModel>> {
    return this.httpClient.post<APIResponse<ResourceModel>>(
      `${this.apiBaseUrl}/resources/${type}`,
      request,
    );
  }
}
