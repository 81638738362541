<dmc-ng-base-dialog
  [inline]="false"
  (closeDialog)="data.clickOnCancel()"
>
  <dmc-ng-icon
    dialog-icon
    [color]="iconColorEnum.Error"
    [theme]="iconThemeEnum.LightCircleOutline"
    [size]="iconSizeEnum.LG"
    icon="trash-03"
  ></dmc-ng-icon>
  <h3 dialog-title>Delete {{ data.type }}</h3>

  <p dialog-content>
    Do you want to delete the {{ data.type }} named "{{ data.name }}" ?
  </p>

  <div dialog-actions>
    <button
      mat-stroked-button
      color="secondary"
      class="medium button"
      (click)="data.clickOnCancel()"
      [autofocus]="false"
    >
      Cancel
    </button>
    <button
      mat-raised-button
      color="primary"
      class="medium button destructive"
      (click)="data.clickOnDelete()"
      [autofocus]="false"
    >
      Delete
    </button>
  </div>
</dmc-ng-base-dialog>
