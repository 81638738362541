import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { InputComponent } from '@dmc-ng/ui/form';
import {
  IconColorEnum,
  IconComponent,
  IconSizeEnum,
  IconThemeEnum,
} from '@dmc-ng/ui/icon';

import {
  CHROME_SETTINGS_PATH,
  ClipboardPermissionDialogModel,
} from '../../models/clipboard-permission-dialog.model';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

@Component({
  selector: 'dmc-ng-clipboard-permission-dialog',
  templateUrl: './clipboard-permission-dialog.component.html',
  styleUrls: ['./clipboard-permission-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    IconComponent,
    ReactiveFormsModule,
    FormsModule,
    InputComponent,
    BaseDialogComponent,
  ],
  standalone: true,
})
export class ClipboardPermissionDialogComponent {
  readonly iconSizeEnum: typeof IconSizeEnum = IconSizeEnum;
  readonly iconThemeEnum: typeof IconThemeEnum = IconThemeEnum;
  readonly iconColorEnum: typeof IconColorEnum = IconColorEnum;

  clipboardSettingUrl: FormControl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ClipboardPermissionDialogModel,
  ) {
    const encodedUrl = encodeURIComponent(window.location.origin);
    const fullUrl = `${CHROME_SETTINGS_PATH}?site=${encodedUrl}`;

    this.clipboardSettingUrl = new FormControl({
      value: fullUrl,
      disabled: true,
    });
  }
}
