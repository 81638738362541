<dmc-ng-base-dialog
  [inline]="true"
  [withClose]="false"
>
  <h3 dialog-title>A new version is available !</h3>

  <p dialog-content>
    A new version of the application is available. To take advantage of the
    latest improvements, please reload the application.
  </p>

  <div dialog-actions>
    <button
      mat-raised-button
      color="primary"
      class="medium button"
      (click)="data.clickOnReload()"
      [autofocus]="false"
    >
      Reload
    </button>
  </div>
</dmc-ng-base-dialog>
