import { Injectable } from '@angular/core';
import { APIResponse, UploadLogoModel } from '@dmc-ng/data-access';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, of, switchMap } from 'rxjs';

import { userActions } from './user.actions';
import { ScopedUserModel, UserApiModel } from '../models/user-api.model';
import { UserService } from '../user.service';

@Injectable()
export class UserEffects {
  createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.createUser),
      switchMap(({ user }) =>
        this.userService.postUser(user).pipe(
          map((createdUser: APIResponse<ScopedUserModel>) =>
            userActions.userCreatedSuccessfully({ user: createdUser }),
          ),
          catchError((error) =>
            of(userActions.userFailedToBeCreated({ error })),
          ),
        ),
      ),
    ),
  );

  userDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getUserDetails),
      switchMap(({ userId }) =>
        this.userService.getUser(userId).pipe(
          map((data: APIResponse<ScopedUserModel>) =>
            userActions.userDetailsRetrievedSuccessfully({ user: data }),
          ),
          catchError((error) =>
            of(userActions.userDetailsFailedToBeRetrieved({ error })),
          ),
        ),
      ),
    ),
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.updateUser),
      switchMap(({ userId, user, logo }) =>
        this.userService.putUser(userId, user).pipe(
          map((updatedUser: APIResponse<ScopedUserModel>) =>
            userActions.userUpdatedSuccessfully({
              user: updatedUser.data as ScopedUserModel,
              logo,
            }),
          ),
          catchError((error) =>
            of(userActions.userFailedToBeUpdated({ error })),
          ),
        ),
      ),
    ),
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.deleteUser),
      switchMap(({ userId, organizationId }) =>
        this.userService.deleteUser(userId, organizationId).pipe(
          map(() => userActions.userDeleteSuccessfully({ userId })),
          catchError((error) =>
            of(userActions.userFailedToBeDeleted({ error })),
          ),
        ),
      ),
    ),
  );

  addLogoForUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.userUpdatedSuccessfully),
      filter(({ logo }) => !!logo),
      map(({ user, logo }) =>
        userActions.addLogoForUser({
          userId: user.id,
          uploadLogo: logo as UploadLogoModel,
        }),
      ),
    ),
  );

  saveLogoToUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.addLogoForUser),
      switchMap(({ userId, uploadLogo }) =>
        this.userService.postProfileLogo(userId, uploadLogo).pipe(
          map((updatedUser: APIResponse<ScopedUserModel>) =>
            userActions.userLogoAddedSuccessfully({
              user: updatedUser.data as ScopedUserModel,
            }),
          ),
          catchError((error) =>
            of(userActions.userLogoFailedToBeAdded({ error })),
          ),
        ),
      ),
    ),
  );

  getUsersWithSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getUsersWithSearch),
      switchMap(({ organizationId, query, limit, withPhone, withSU }) =>
        this.userService
          .searchUsers(organizationId, query, limit, withPhone, withSU)
          .pipe(
            map((usersResult: APIResponse<UserApiModel[]>) =>
              userActions.usersSearchedSuccessfully({
                users: usersResult.data,
              }),
            ),
            catchError((error) =>
              of(
                userActions.usersFailedToBeSearched({
                  error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
  ) {}
}
