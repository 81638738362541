import { Directive, Input, OnInit } from '@angular/core';
import { GridOptions, RowClickedEvent } from 'ag-grid-community';

import { PermissionsService } from '../services/permissions.service';

@Directive({
  selector: '[dmcConsoleAgGridAction]',
  standalone: true,
})
export class AgGridActionDirective implements OnInit {
  @Input({ required: true }) gridOptions: GridOptions = {};
  @Input() clickPermission?: string;
  @Input() clickAction!: (event: RowClickedEvent) => void;

  constructor(private permissionService: PermissionsService) {}

  ngOnInit(): void {
    this.setupGridOptions();
  }

  private setupGridOptions(): void {
    const hasPermission = this.hasPermission();

    this.gridOptions.rowClass = hasPermission ? 'pointer' : '';

    if (this.gridOptions.onRowClicked) {
      const originalOnRowClicked = this.gridOptions.onRowClicked;

      this.gridOptions.onRowClicked = (event: RowClickedEvent): void => {
        if (this.hasPermission() && this.isValidClickAction()) {
          this.clickAction(event);
        } else {
          event.event?.stopPropagation();
        }

        if (originalOnRowClicked) {
          originalOnRowClicked(event);
        }
      };
    } else {
      this.gridOptions.onRowClicked = (event: RowClickedEvent): void => {
        if (this.hasPermission() && this.isValidClickAction()) {
          this.clickAction(event);
        } else {
          event.event?.stopPropagation();
        }
      };
    }
  }

  private hasPermission(): boolean {
    return this.permissionService.hasPermission(this.clickPermission);
  }

  private isValidClickAction(): boolean {
    return typeof this.clickAction === 'function';
  }
}
