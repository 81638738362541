import { Injectable } from '@angular/core';
import { IdentityService } from '@dmc-ng/authentication';
import { meActions } from '@dmc-ng/data-access/me';
import {
  OrganizationModel,
  organizationsActions,
} from '@dmc-ng/data-access/organizations';
import { resourcesActions } from '@dmc-ng/data-access/resources';
import { userActions } from '@dmc-ng/data-access/user';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs';

import { dmcConsoleActions } from './dmc-console.actions';
import { UserUtils } from '../utils/user.utils';

@Injectable()
export class DmcConsoleEffects {
  setUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.setCurrentUser),
      map(({ user }) =>
        dmcConsoleActions.setCurrentUser({ user: UserUtils.convertUser(user) }),
      ),
    ),
  );

  loadOrganizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dmcConsoleActions.setCurrentUser),
      map(() => organizationsActions.getOrganizations({ withAccounts: true })),
    ),
  );

  loadPolicies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dmcConsoleActions.setCurrentUser),
      map(() => meActions.getPolicies()),
    ),
  );

  loadResources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dmcConsoleActions.setCurrentUser),
      map(() => resourcesActions.getResources()),
    ),
  );

  sendInvitation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.userCreatedSuccessfully),
        filter(({ user }) => !!user),
        map(({ user }) => {
          if (user.data) {
            this.identityService.resetPassword(user.data.emailAddress);
          }
        }),
      ),
    { dispatch: false },
  );

  setDetailedCurrentOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(organizationsActions.organizationRetrievedSuccessfully),
      map(({ organization }) =>
        dmcConsoleActions.setDetailedCurrentOrganization({
          organization: organization.data as OrganizationModel,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private identityService: IdentityService,
    private store: Store,
  ) {}
}
