import { Component } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TooltipComponent } from '@dmc-ng/ui/tooltip';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

interface ChipListParams extends ICellRendererParams {
  delete: (chipValue: string, rowData: any) => void;
  chips: string[];
}

@Component({
  standalone: true,
  imports: [
    MatChipsModule,
    MatTooltipModule,
    MatChipsModule,
    MatIconModule,
    TooltipComponent,
  ],
  templateUrl: './chip-list-cell.component.html',
  styleUrl: './chip-list-cell.component.scss',
})
export class ChipListCellRendererComponent implements ICellRendererAngularComp {
  params!: ChipListParams;
  displayedChips: string[] = [];
  extraChipsCount = 0;

  agInit(params: ChipListParams): void {
    this.params = params;
    this.updateChips();
  }

  refresh(params: ChipListParams): boolean {
    this.updateChips();
    return true;
  }

  removeTag(chip: string): void {
    this.params.delete(chip, this.params.data);
  }

  private updateChips(): void {
    const totalChips = this.params.chips ?? [];
    this.displayedChips = totalChips.slice(0, 3);
    this.extraChipsCount = totalChips.length > 3 ? totalChips.length - 3 : 0;
  }
}
