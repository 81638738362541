import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstLetter',
  standalone: true,
})
export class FirstLetterPipe implements PipeTransform {
  transform(value: string | undefined | null): string {
    if (value) return value[0];
    return '';
  }
}
