<div
  class="cell-container d-flex align-items-center h-100"
  (click)="$event.stopPropagation()"
>
  <mat-checkbox
    [checked]="params.isChecked()"
    class="small"
    (change)="onClick($event)"
  ></mat-checkbox>
</div>
