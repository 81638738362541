import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

interface dateParams extends ICellRendererParams {
  createdAt: string;
  withTime?: boolean;
}

@Component({
  standalone: true,
  imports: [CommonModule],
  templateUrl: './datetime.component.html',
  styleUrl: './datetime.component.scss',
})
export class DateTimeCellRendererComponent implements ICellRendererAngularComp {
  createdAt!: string;
  withTime = true;

  agInit(params: dateParams): void {
    this.createdAt = params.createdAt;

    this.withTime = params.withTime ?? this.withTime;
  }

  refresh(params: dateParams): boolean {
    this.createdAt = params.createdAt;
    return true;
  }
}
