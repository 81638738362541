export enum PermissionEnum {
  OrganizationsList = 'organizations.list',
  OrganizationsRead = 'organizations.read',
  OrganizationsWrite = 'organizations.write',
  AccountList = 'accounts.list',
  AccountRead = 'accounts.read',
  AccountWrite = 'accounts.write',
  CountingRead = 'countings.read',
  CountingWrite = 'countings.write',
  DataCustomFieldRead = 'data_custom_field.read',
  DataImportRead = 'data_import.read',
  DataImportWrite = 'data_import.write',
  OrganizationUserRead = 'organization_users.read',
  OrganizationUserWrite = 'organization_users.write',
  POIDataRead = 'poi_data.read',
  POIDataWrite = 'poi_data.write',
  CampaignsList = 'campaigns.list',
  CampaignsRead = 'campaigns.read',
  CampaignsWrite = 'campaigns.write',
  CampaignsLaunch = 'campaigns.launch', // only SU
  CampaignsRevertToDraft = 'campaigns.revert_to_draft', //only SU
  ResourcesRead = 'resources.read',
  PricingPlanWrite = 'pricing_plan.write',
  SMSDomainWrite = 'sms_domain.write',
  DataEditorList = 'data_editor.list',
  DataEditorRead = 'data_editor.read',
  DataEditorWrite = 'data_editor.write',
}
