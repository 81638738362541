import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  APIResponse,
  DeepPartial,
  PaginatedResponse,
  UploadLogoModel,
} from '@dmc-ng/data-access';
import { API_BASE_URL } from '@dmc-ng/data-access/injection-token';
import { Observable } from 'rxjs';

import { OrganizationModel } from './models/organizations.model';

@Injectable()
export class OrganizationsService {
  private readonly apiBaseUrl = inject(API_BASE_URL);

  constructor(private readonly httpClient: HttpClient) {}

  getPaginatedOrganizations(
    withAccounts?: boolean,
    offset?: number,
    limit?: number,
  ): Observable<APIResponse<PaginatedResponse<OrganizationModel>>> {
    let params = new HttpParams();

    if (withAccounts) {
      params = params.append('accounts', withAccounts);
    }
    if (offset) {
      params = params.append('offset', offset);
    }
    if (limit) {
      params = params.append('limit', limit);
    }
    return this.httpClient.get<
      APIResponse<PaginatedResponse<OrganizationModel>>
    >(`${this.apiBaseUrl}/organizations/`, { params });
  }

  getOrganizations(
    withAccounts?: boolean,
  ): Observable<APIResponse<OrganizationModel[]>> {
    let params = new HttpParams();

    if (withAccounts) {
      params = params.append('accounts', withAccounts);
    }
    return this.httpClient.get<APIResponse<OrganizationModel[]>>(
      `${this.apiBaseUrl}/organizations/all`,
      { params },
    );
  }

  postOrganization(
    organizationToSave: DeepPartial<OrganizationModel>,
  ): Observable<APIResponse<OrganizationModel>> {
    return this.httpClient.post<APIResponse<OrganizationModel>>(
      `${this.apiBaseUrl}/organizations/`,
      organizationToSave,
    );
  }

  getOrganization(
    organizationId: string,
  ): Observable<APIResponse<OrganizationModel>> {
    return this.httpClient.get<APIResponse<OrganizationModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}`,
    );
  }

  putOrganization(
    organizationId: string,
    organizationToEdit: DeepPartial<OrganizationModel>,
  ): Observable<APIResponse<OrganizationModel>> {
    return this.httpClient.put<APIResponse<OrganizationModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}`,
      organizationToEdit,
    );
  }

  postOrganizationLogo(
    organizationId: string,
    uploadLogo: UploadLogoModel,
  ): Observable<APIResponse<OrganizationModel>> {
    return this.httpClient.post<APIResponse<OrganizationModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/logo`,
      uploadLogo,
    );
  }

  deleteOrganization(organizationId: string): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.apiBaseUrl}/organizations/${organizationId}`,
    );
  }
}
