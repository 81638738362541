import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  APIResponse,
  DeepPartial,
  PaginatedResponse,
} from '@dmc-ng/data-access';
import { API_BASE_URL } from '@dmc-ng/data-access/injection-token';
import { SortModel, SortUtils } from '@dmc-ng/utils';
import { Observable } from 'rxjs';

import { BatModel } from './models/bat.model';
import { CampaignModel, CampaignStateEnum } from './models/campaign.model';
import { CampaignsMetricsOverviewModel } from './models/campaigns-metrics-overview.model';
import { ChangeStateEnum } from './models/enum/change-state.enum';

@Injectable()
export class CampaignsService {
  private readonly apiBaseUrl = inject(API_BASE_URL);

  constructor(private readonly httpClient: HttpClient) {}

  getCampaigns(
    organizationId: string,
    acoountId: string,
    query?: string,
    limit = 10,
    status?: CampaignStateEnum[],
  ): Observable<APIResponse<CampaignModel[]>> {
    let params = new HttpParams();
    params = params.append('limit', limit);

    if (query) {
      params = params.append('q', query);
    }
    if (status) {
      params = params.append('status', status.join(','));
    }
    return this.httpClient.get<APIResponse<CampaignModel[]>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${acoountId}/campaigns/all`,
      { params },
    );
  }

  getPaginatedCampaigns(
    organizationId: string,
    accountId: string,
    offset?: number,
    limit?: number,
    status?: CampaignStateEnum[],
    sort?: SortModel,
  ): Observable<APIResponse<PaginatedResponse<CampaignModel>>> {
    let params = new HttpParams();

    if (offset) {
      params = params.append('offset', offset);
    }
    if (limit) {
      params = params.append('limit', limit);
    }
    if (status) {
      params = params.append('status', status.join(','));
    }
    if (sort) {
      params = params.append('sort', SortUtils.createQueryParams(sort));
    }
    return this.httpClient.get<APIResponse<PaginatedResponse<CampaignModel>>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/campaigns/`,
      { params },
    );
  }

  getOneCampaign(
    organizationId: string,
    accountId: string,
    campaignId: string,
  ): Observable<APIResponse<CampaignModel>> {
    return this.httpClient.get<APIResponse<CampaignModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/campaigns/${campaignId}`,
    );
  }

  createCampaign(
    organizationId: string,
    accountId: string,
    campaign: DeepPartial<CampaignModel>,
  ): Observable<APIResponse<CampaignModel>> {
    return this.httpClient.post<APIResponse<CampaignModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/campaigns/`,
      campaign,
    );
  }

  editCampaign(
    organizationId: string,
    accountId: string,
    campaignId: string,
    campaign: DeepPartial<CampaignModel>,
  ): Observable<APIResponse<CampaignModel>> {
    return this.httpClient.post<APIResponse<CampaignModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/campaigns/${campaignId}`,
      campaign,
    );
  }

  updateCampaign(
    organizationId: string,
    accountId: string,
    campaignId: string,
    campaignToEdit: DeepPartial<CampaignModel>,
  ): Observable<APIResponse<CampaignModel>> {
    return this.httpClient.put<APIResponse<CampaignModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/campaigns/${campaignId}`,
      campaignToEdit,
    );
  }

  deleteCampaign(
    organizationId: string,
    accountId: string,
    campaignId: string,
  ): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/campaigns/${campaignId}`,
    );
  }

  changeCampaignState(
    organizationId: string,
    accountId: string,
    campaignId: string,
    changeState: ChangeStateEnum,
  ): Observable<void> {
    return this.httpClient.post<void>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/campaigns/${campaignId}/change-state`,
      { event: changeState },
    );
  }

  launchBat(
    organizationId: string,
    accountId: string,
    bat: BatModel,
  ): Observable<void> {
    return this.httpClient.post<void>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/campaigns/bat`,
      bat,
    );
  }

  getCampaignsMetrics(
    organizationId: string,
    accountId: string,
    startDate: string,
    endDate: string,
  ): Observable<APIResponse<CampaignsMetricsOverviewModel>> {
    const params = new HttpParams()
      .append('start-date', startDate)
      .append('end-date', endDate);

    return this.httpClient.get<APIResponse<CampaignsMetricsOverviewModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/campaigns/overview`,
      { params },
    );
  }
}
