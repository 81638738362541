import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  UrlTree,
} from '@angular/router';
import {
  OrganizationModel,
  organizationsActions,
  selectOrganizations,
} from '@dmc-ng/data-access/organizations';
import { select, Store } from '@ngrx/store';
import { map, Observable, take } from 'rxjs';

import { dmcConsoleActions } from '../state/dmc-console.actions';
import { RoutingUtils } from '../utils/routing.utils';

export const organizationAndAccountGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
): Observable<boolean | UrlTree> => {
  const store: Store = inject(Store);
  const router = inject(Router);

  return store.pipe(
    select(selectOrganizations),
    take(1),
    map((organizations: OrganizationModel[]) => {
      const currentOrganizationId: string | undefined =
        route.params[RoutingUtils.routeParams.organizationId];
      const currentAccountId: string | undefined =
        route.queryParams[RoutingUtils.queryParams.accountId];
      const currentOrganization: OrganizationModel | undefined =
        organizations.find(
          (org: OrganizationModel) => org.id === currentOrganizationId,
        );
      const currentUrlSegments: string[] =
        RoutingUtils.buildUrlFromRoute(route);

      // MANAGE MEDIA PLANNING ROUTING WITH 'COUNTING' PATH AND REDIRECT TO CREATE SETUP
      if (
        currentUrlSegments.includes('counting') &&
        !currentUrlSegments.includes('create')
      ) {
        currentUrlSegments.pop();
      }

      return getRedirectUrl(
        store,
        router,
        route,
        currentOrganizationId,
        currentAccountId,
        currentOrganization,
        currentUrlSegments,
      );
    }),
  );
};

const getRedirectUrl = (
  store: Store,
  router: Router,
  route: ActivatedRouteSnapshot,
  currentOrganizationId: string | undefined,
  currentAccountId: string | undefined,
  currentOrganization: OrganizationModel | undefined,
  currentUrlSegments: string[],
): boolean | UrlTree => {
  let redirectUrl: UrlTree | undefined;

  if (currentOrganization) {
    store.dispatch(
      organizationsActions.getOneOrganization({
        organizationId: currentOrganizationId as string,
      }),
    );

    redirectUrl = handleExistingOrganization(
      router,
      route,
      currentAccountId,
      currentOrganization,
      currentUrlSegments,
    );
  } else {
    redirectUrl = handleNonExistingOrganization(store, router);
  }

  if (redirectUrl) {
    return redirectUrl;
  }

  // Dispatch current OrganizationId and current AccountId
  setCurrentOrganizationIdAndAccountId(
    store,
    currentOrganizationId,
    currentAccountId,
  );

  return true;
};

const handleExistingOrganization = (
  router: Router,
  route: ActivatedRouteSnapshot,
  currentAccountId: string | undefined,
  currentOrganization: OrganizationModel,
  currentUrlSegments: string[],
): UrlTree | undefined => {
  const accounts = currentOrganization.accounts || [];

  if (accounts.length) {
    if (
      !currentAccountId ||
      !accounts.some((acc) => acc.id === currentAccountId)
    ) {
      // No AccountId params or AccountId params is not in the organization list, use the first account in the list
      return router.createUrlTree(currentUrlSegments, {
        queryParams: {
          ...route.queryParams,
          [RoutingUtils.queryParams.accountId]: accounts[0].id,
        },
      });
    }
  } else {
    if (currentAccountId) {
      // AccountId params exist, remove unused routing segments to just keep feature segment
      return router.createUrlTree(currentUrlSegments.slice(0, 2), {
        queryParams: {},
      });
    }
  }

  return undefined;
};

const handleNonExistingOrganization = (
  store: Store,
  router: Router,
): UrlTree => {
  setCurrentOrganizationIdAndAccountId(store, undefined, undefined);
  return router.createUrlTree([RoutingUtils.pages.error404]);
};

const setCurrentOrganizationIdAndAccountId = (
  store: Store,
  organizationId: string | undefined,
  accountId: string | undefined,
): void => {
  store.dispatch(
    dmcConsoleActions.setCurrentOrganizationIdAndAccountId({
      organizationId,
      accountId,
    }),
  );
};
