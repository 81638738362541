export const ACCOUNT_MANAGEMENT_MEMBERS_TAB =
  /\/account-management\/all\?tab=members(?:.*)?$/;
export const ACCOUNT_MANAGEMENT_ORGANIZATIONS_TAB =
  /\/account-management\/all\?tab=organizations(?:.*)?$/;
export const ACCOUNT_MANAGEMENT = /\/account-management/;
export const ACCOUNT_MANAGEMENT_LIST = /^\/account-management\/all(?:\/.*)?/;
export const ACCOUNT_MANAGEMENT_MEMBERS_SECTION =
  /\/account-management\/[a-zA-Z0-9]+\?section=members(?:.*)?$/;
export const ACCOUNT_MANAGEMENT_ACCOUNTS_SECTION =
  /\/account-management\/[a-zA-Z0-9]+\?section=accounts(?:.*)?$/;
export const ACCOUNT_MANAGEMENT_SMS_SECTION =
  /\/account-management\/[a-zA-Z0-9]+\?section=sms(?:.*)?$/;

export const CAMPAIGN = /\/campaign/;
export const CAMPAIGN_LIST = /\/campaign\/all(?:\/.*)?/;

export const MEDIA_PLANNING = /\/media-planning/;
export const MEDIA_PLANNING_LIST = /\/media-planning\/all(?:\/.*)?/;

export const OVERVIEW = /\/overview/;
export const OVERVIEW_DETAILS = /\/overview\/details/;
export const PROFILE = /\/profile/;

export const DATA_EDITOR = /\/data-editor/;
export const DATA_EDITOR_LIST = /\/data-editor\/all(?:\/.*)?/;
export const DATA_EDITOR_POI_DATA_LIST = /\/data-editor\/[a-zA-Z0-9]+(?:\/.*)?/;
