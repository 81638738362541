import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { overlayActions } from './overlay.actions';
import { OverlayUtils } from '../utils/overlay.utils';

@Injectable()
export class OverlayEffects {
  showOverlay$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(overlayActions.openOverlay),
        tap(({ customComponent, config }) => {
          this.overlayRef = this.overlay.create(OverlayUtils.createOverlayConfiguration(config, this.overlay));

          const injector = Injector.create({
            providers: [{ provide: DIALOG_DATA, useValue: config }],
          });
          const componentPortal = new ComponentPortal(customComponent, null, injector);
          this.overlayRef.attach(componentPortal);
        })
      ),
    { dispatch: false }
  );

  closeOverlay$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(overlayActions.closeOverlay),
        tap(() => {
          if (this.overlayRef && this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
          }
        })
      ),
    { dispatch: false }
  );

  private overlayRef: OverlayRef | undefined;

  constructor(
    private overlay: Overlay,
    private actions$: Actions
  ) {}
}
