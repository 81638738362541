import { Type } from '@angular/core';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { CustomOverlayConfig } from '../models/overlay.model';

export const overlayActions = createActionGroup({
  source: 'Overlay',
  events: {
    'Open Overlay': props<{ customComponent: Type<any>, config: CustomOverlayConfig }>(),
    'Close Overlay': emptyProps(),
  },
});
