import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
  standalone: true,
})
export class FileSizePipe implements PipeTransform {
  transform(value: number): string {
    if (value) {
      if (value < 1000000) {
        return `${(value / 1000).toFixed(2)} KB`;
      }

      return `${(value / 1000000).toFixed(2)} MB`;
    } else {
      return '';
    }
  }
}
