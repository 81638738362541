<div class="cell-container d-flex align-items-center h-100 gap-3 w-100">
  <dmc-ng-avatar
    [classType]="'circle'"
    [width]="32"
    [pictureUrl]="pictureUrl"
    [value]="displayName"
  ></dmc-ng-avatar>
  <dmc-ng-tooltip
    class="user-name"
    [width]="'100%'"
    [text]="displayName"
  ></dmc-ng-tooltip>
</div>
