import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat',
  standalone: true,
})
export class NumberFormatPipe implements PipeTransform {
  transform(
    value: number,
    currency: boolean = false,
    withFloat = false,
  ): string {
    if (!isFinite(value)) return value.toString();

    let formattedNumber = new Intl.NumberFormat('fr-FR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);

    if (Number.isInteger(value) && !withFloat) {
      formattedNumber = new Intl.NumberFormat('fr-FR').format(value);
    }

    if (currency) {
      formattedNumber += ' €';
    }

    return formattedNumber;
  }
}
