<div
  class="top"
  mat-dialog-title
>
  <div
    class="logo-title"
    [ngClass]="inline ? 'row' : 'column'"
  >
    <ng-content select="[dialog-icon]"></ng-content>
    <ng-content select="[dialog-title]"></ng-content>
  </div>

  @if (withClose) {
    <button
      mat-icon-button
      aria-label="Close button"
      (click)="closeDialog.emit()"
      class="icon-button close"
    >
      <mat-icon
        class="icon gray-stroke-400"
        svgIcon="x-close"
      ></mat-icon>
    </button>
  }
</div>
<mat-dialog-content>
  <ng-content select="[dialog-content]"></ng-content>
</mat-dialog-content>
@if (withActions) {
  <mat-dialog-actions>
    <ng-content select="[dialog-actions]"></ng-content>
  </mat-dialog-actions>
}
