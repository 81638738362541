<mat-paginator
  [length]="total"
  [pageSize]="pageSize"
  aria-label="Select page"
  [pageIndex]="index"
  (page)="pageChanged($event)"
  [showFirstLastButtons]="true"
  [hidePageSize]="hidePageSize"
  [pageSizeOptions]="[5, 10, 25, 100]"
/>
