export enum FeatureTypeEnum {
  Account = 'account',
  Campaign = 'campaign',
  Counting = 'counting',
  CountingVersion = 'counting version',
  Domain = 'domain',
  Member = 'member',
  Organization = 'organization',
  Poi = 'poi',
  DataImport = 'data import',
  Tag = 'tag',
}
