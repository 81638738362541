<dmc-ng-base-dialog
  [inline]="false"
  (closeDialog)="data.clickOnCancel()"
>
  <dmc-ng-icon
    dialog-icon
    [color]="iconColorEnum.Warning"
    [theme]="iconThemeEnum.LightCircleOutline"
    [size]="iconSizeEnum.LG"
    icon="save"
  ></dmc-ng-icon>
  <h3 dialog-title>Unsaved changes</h3>

  <p dialog-content>If you leave, you'll lose your changes.</p>

  <div dialog-actions>
    <button
      mat-stroked-button
      color="secondary"
      class="medium"
      (click)="data.clickOnCancel()"
      [autofocus]="false"
    >
      Cancel
    </button>
    <button
      mat-raised-button
      color="primary"
      class="medium"
      (click)="data.clickOnLeave()"
    >
      Leave without saving
    </button>
  </div>
</dmc-ng-base-dialog>
