import { LoadingStateEnum } from '@dmc-ng/data-access';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { organizationsActions } from './organizations.actions';
import { OrganizationModel } from '../models/organizations.model';
import { OrganizationsUtils } from '../utils/organizations.utils';

export const ORGANIZATIONS_FEATURE_KEY = 'organizations';

export const organizationsAdapter: EntityAdapter<OrganizationModel> =
  createEntityAdapter<OrganizationModel>();
export const paginatedOrganizationAdapter: EntityAdapter<OrganizationModel> =
  createEntityAdapter<OrganizationModel>({
    selectId: (organization) => organization.id,
  });

export interface PaginatedOrganizationsState
  extends EntityState<OrganizationModel> {
  loading: LoadingStateEnum;
  total?: number;
  totalInCurrentOffset?: number;
  error?: unknown;
}

export interface OrganizationsState extends EntityState<OrganizationModel> {
  error?: unknown;
  loading: LoadingStateEnum;
  loadingOneOrganization: LoadingStateEnum;
  paginatedOrganizations: PaginatedOrganizationsState;
}

export const initialOrganizationsState: OrganizationsState =
  organizationsAdapter.getInitialState({
    loading: LoadingStateEnum.Ready,
    loadingOneOrganization: LoadingStateEnum.Ready,
    paginatedOrganizations: paginatedOrganizationAdapter.getInitialState({
      loading: LoadingStateEnum.Ready,
    }),
  });

const reducer = createReducer(
  initialOrganizationsState,
  on(organizationsActions.getPaginatedOrganizations, (state) => ({
    ...state,
    paginatedOrganizations: {
      ...state.paginatedOrganizations,
      loading: LoadingStateEnum.Loading,
    },
  })),
  on(
    organizationsActions.organizationsPaginatedRetrievedSuccessfully,
    (state, { organizations, total, totalInCurrentOffset }) => ({
      ...state,
      paginatedOrganizations: {
        ...paginatedOrganizationAdapter.setAll(
          organizations,
          state.paginatedOrganizations,
        ),
        total,
        totalInCurrentOffset,
        loading: LoadingStateEnum.Done,
      },
    }),
  ),
  on(
    organizationsActions.organizationsPaginatedFailedToBeRetrieved,
    (state, { error }) => ({
      ...state,
      paginatedOrganizations: {
        ...state.paginatedOrganizations,
        loading: LoadingStateEnum.Done,
        error,
      },
    }),
  ),
  on(
    organizationsActions.getOrganizations,
    organizationsActions.createOrganization,
    organizationsActions.updateOrganization,
    organizationsActions.addLogoForOrganization,
    organizationsActions.deleteOrganization,
    (state) => ({ ...state, loading: LoadingStateEnum.Loading }),
  ),
  on(
    organizationsActions.organizationsRetrievedSuccessfully,
    organizationsActions.organizationCreatedSuccessfully,
    organizationsActions.organizationUpdatedSuccessfully,
    organizationsActions.organizationLogoAddedSuccessfully,
    (state) => ({ ...state, loading: LoadingStateEnum.Done }),
  ),
  on(
    organizationsActions.organizationsFailedToBeRetrieved,
    organizationsActions.organizationFailedToBeCreated,
    organizationsActions.organizationFailedToBeUpdated,
    organizationsActions.organizationLogoFailedToBeAdded,
    organizationsActions.organizationFailedToBeDeleted,
    (state, { error }) => ({ ...state, error, loading: LoadingStateEnum.Done }),
  ),
  on(
    organizationsActions.organizationsRetrievedSuccessfully,
    (state, { organizations }) =>
      organizationsAdapter.upsertMany(organizations, {
        ...state,
        loading: LoadingStateEnum.Done,
      }),
  ),
  on(
    organizationsActions.organizationCreatedSuccessfully,
    organizationsActions.organizationUpdatedSuccessfully,
    organizationsActions.organizationLogoAddedSuccessfully,
    (state, { organization }) =>
      organizationsAdapter.upsertOne(
        OrganizationsUtils.convertOneOrganization(
          organization.data as OrganizationModel,
        ),
        {
          ...state,
          loading: LoadingStateEnum.Done,
        },
      ),
  ),
  on(
    organizationsActions.organizationDeleteSuccessfully,
    (state, { organizationId }) =>
      organizationsAdapter.removeOne(organizationId, {
        ...state,
        loading: LoadingStateEnum.Done,
      }),
  ),
  on(organizationsActions.clearOrganizations, (state) =>
    organizationsAdapter.removeAll({
      ...state,
      loading: LoadingStateEnum.Ready,
    }),
  ),
  on(organizationsActions.getOneOrganization, (state) => ({
    ...state,
    loadingOneOrganization: LoadingStateEnum.Loading,
  })),
  on(organizationsActions.organizationRetrievedSuccessfully, (state) => ({
    ...state,
    loadingOneOrganization: LoadingStateEnum.Done,
  })),
  on(
    organizationsActions.organizationFailedToBeRetrieved,
    (state, { error }) => ({
      ...state,
      error,
      loadingOneOrganization: LoadingStateEnum.Done,
    }),
  ),
);

export function organizationsReducer(
  state: OrganizationsState | undefined,
  action: Action,
): OrganizationsState {
  return reducer(state, action);
}
