import { Injectable } from '@angular/core';
import { IDBPDatabase, IDBPIndex, IDBPObjectStore, openDB } from 'idb';

interface IndexedDbSchema {
  DataEditorStore: {
    key: number;
    value: IndexedDbItem;
    indexes: { errorIndex: string };
  };
}
interface IndexedDbItem {
  [key: string]: any;
  id?: number;
  error?: any[];
  hasError?: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class IndexedDbService {
  customId = 1;
  private dbPromise: Promise<IDBPDatabase<IndexedDbSchema>>;

  constructor() {
    this.dbPromise = this.initDB();
  }

  async initDB(): Promise<IDBPDatabase<IndexedDbSchema>> {
    return openDB<IndexedDbSchema>('DekupleDatabase', 2, {
      upgrade(db) {
        if (!db.objectStoreNames.contains('DataEditorStore')) {
          const store = db.createObjectStore('DataEditorStore', {
            keyPath: 'id',
            autoIncrement: false,
          });
          store.createIndex('errorIndex', 'hasError', { unique: false });
        }
      },
    });
  }

  handleError(error: unknown, context: string): void {
    console.error(`Failed to ${context}:`, error);
  }

  async withTransaction<T>({
    storeName,
    mode,
    callback,
  }: {
    storeName: keyof IndexedDbSchema;
    mode: 'readonly' | 'readwrite';
    callback: (
      store: IDBPObjectStore<
        IndexedDbSchema,
        ['DataEditorStore'],
        'DataEditorStore',
        typeof mode
      >,
    ) => Promise<T>;
  }): Promise<T> {
    try {
      const db = await this.dbPromise;
      const transaction = db.transaction(storeName, mode);
      const store = transaction.objectStore(
        'DataEditorStore',
      ) as IDBPObjectStore<
        IndexedDbSchema,
        ['DataEditorStore'],
        'DataEditorStore',
        typeof mode
      >;
      const result = await callback(store);
      await transaction.done;
      return result;
    } catch (error) {
      this.handleError(error, `perform transaction on ${storeName}`);
      throw error;
    }
  }

  async addData(data: IndexedDbItem[]): Promise<void> {
    if (!Array.isArray(data) || data.length === 0) {
      console.error('Invalid data for addData:', data);
      throw new Error('Data must be a non-empty array.');
    }

    await this.withTransaction({
      storeName: 'DataEditorStore',
      mode: 'readwrite',
      callback: async (store) => {
        for (const item of data) {
          item.id = this.customId++;
          item.hasError =
            item.error && item.error.length > 0 ? 'hasError' : null;
          if (store.put) {
            await store.put(item);
          } else {
            console.error('Store method put is undefined');
          }
        }
      },
    });

    await this.verifyErrorIndex();
  }

  async getCursorData(
    store:
      | IDBPObjectStore<
          IndexedDbSchema,
          ['DataEditorStore'],
          'DataEditorStore',
          'readonly'
        >
      | IDBPIndex<
          IndexedDbSchema,
          ['DataEditorStore'],
          'DataEditorStore',
          'errorIndex',
          'readonly'
        >,
    startIndex: number,
    limit: number,
  ): Promise<IndexedDbItem[]> {
    const results: IndexedDbItem[] = [];
    let indexCounter = 0;
    let cursor = await store.openCursor();

    while (cursor) {
      if (indexCounter >= startIndex && results.length < limit) {
        results.push(cursor.value);
      }
      indexCounter++;
      cursor = await cursor.continue();
    }

    return results;
  }

  async getData(
    startIndex: number,
    limit: number,
    filterErrors: boolean = false,
  ): Promise<IndexedDbItem[]> {
    return this.withTransaction({
      storeName: 'DataEditorStore',
      mode: 'readonly',
      callback: async (store) => {
        if (filterErrors) {
          if (!store.indexNames.contains('errorIndex')) {
            throw new Error(
              'Index "errorIndex" not found in the object store.',
            );
          }
          const errorIndex = store.index('errorIndex') as IDBPIndex<
            IndexedDbSchema,
            ['DataEditorStore'],
            'DataEditorStore',
            'errorIndex',
            'readonly'
          >;
          return this.getCursorData(errorIndex, startIndex, limit);
        } else {
          return this.getCursorData(
            store as IDBPObjectStore<
              IndexedDbSchema,
              ['DataEditorStore'],
              'DataEditorStore',
              'readonly'
            >,
            startIndex,
            limit,
          );
        }
      },
    });
  }

  async updateData(updatedData: IndexedDbItem): Promise<void> {
    await this.withTransaction({
      storeName: 'DataEditorStore',
      mode: 'readwrite',
      callback: async (store) => {
        if (store.put) {
          await store.put(updatedData);
        } else {
          console.error('Store method put is undefined');
        }
      },
    });
  }

  async clearData(): Promise<void> {
    await this.withTransaction({
      storeName: 'DataEditorStore',
      mode: 'readwrite',
      callback: async (store) => {
        this.customId = 1;
        if (store.clear) {
          await store.clear();
        } else {
          console.error('Store method clear is undefined');
        }
      },
    });
  }

  async countAllRows(): Promise<number> {
    return await this.withTransaction({
      storeName: 'DataEditorStore',
      mode: 'readonly',
      callback: async (store) => store.count(),
    });
  }

  async verifyErrorIndex(): Promise<void> {
    try {
      const db = await this.dbPromise;
      const transaction = db.transaction('DataEditorStore', 'readonly');
      const store = transaction.objectStore('DataEditorStore');
      const index = store.index('errorIndex') as IDBPIndex<
        IndexedDbSchema,
        ['DataEditorStore'],
        'DataEditorStore',
        'errorIndex',
        'readonly'
      >;

      let cursor = await index.openCursor();
      const results: IndexedDbItem[] = [];

      while (cursor) {
        if (cursor.value.hasError === 'hasError') {
          results.push(cursor.value);
        }
        cursor = await cursor.continue();
      }
    } catch (error) {
      this.handleError(error, 'verify entries in errorIndex');
    }
  }

  async countErrorIndex(): Promise<number> {
    return this.withTransaction({
      storeName: 'DataEditorStore',
      mode: 'readonly',
      callback: async (store) => {
        const index = store.index('errorIndex') as IDBPIndex<
          IndexedDbSchema,
          ['DataEditorStore'],
          'DataEditorStore',
          'errorIndex',
          'readonly'
        >;
        return index.count();
      },
    });
  }
}
