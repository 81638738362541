import { OrganizationModel } from '../models/organizations.model';

export abstract class OrganizationsUtils {
  static convertOrganizations(
    organizations: OrganizationModel[],
  ): OrganizationModel[] {
    return (
      organizations.map((item) =>
        OrganizationsUtils.convertOneOrganization(item),
      ) ?? []
    );
  }

  static convertOneOrganization(
    organization: OrganizationModel,
  ): OrganizationModel {
    return {
      id: organization.id,
      createAt: organization.createAt,
      deactivated: organization.deactivated,
      name: organization.name,
      updateAt: organization.createAt,
      address: organization.address,
      type: organization.type,
      logoUrl: organization.logoUrl,
      websiteUrl: organization.websiteUrl,
      invoiceInformation: organization.invoiceInformation,
      pricingPlans: organization.pricingPlans,
      billingContact: organization.billingContact,
      iabCategory: organization.iabCategory,
      accounts: organization.accounts ?? [],
    };
  }

  static createInputSelectData = (
    organizations: OrganizationModel[],
  ): { value: string; name: string; logo: string }[] =>
    organizations.map((organization) => ({
      value: organization.id,
      name: organization.name,
      logo: organization.logoUrl,
    }));
}
