@for (chip of displayedChips; track chip) {
  <mat-chip-row
    (removed)="removeTag(chip)"
    class="custom multi-select-chip badge fixed"
  >
    <dmc-ng-tooltip
      class="name"
      maxWidth="50px"
      [text]="chip"
    ></dmc-ng-tooltip>
    <button
      matChipRemove
      aria-label="'remove chip ' + keyword"
    >
      <mat-icon
        class="icon gray-stroke-400"
        svgIcon="x-close"
      ></mat-icon>
    </button>
  </mat-chip-row>
}
@if (extraChipsCount > 0) {
  <mat-chip-row class="custom multi-select-chip badge">
    +{{ extraChipsCount }}
  </mat-chip-row>
}
