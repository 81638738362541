<dmc-ng-base-dialog
  [inline]="false"
  (closeDialog)="cancel()"
>
  <dmc-ng-icon
    dialog-icon
    [color]="iconColorEnum.Warning"
    [theme]="iconThemeEnum.LightCircleOutline"
    [size]="iconSizeEnum.LG"
    icon="save"
  ></dmc-ng-icon>
  <h3 dialog-title>You are about to lose your work</h3>

  <p dialog-content>
    Save your work so you can resume it later or start from scratch.
  </p>
  <div dialog-actions>
    <button
      mat-stroked-button
      color="secondary"
      class="medium"
      (click)="cancel()"
      [autofocus]="false"
    >
      Cancel
    </button>
    <button
      mat-raised-button
      color="primary"
      class="medium accept"
      (click)="leave()"
    >
      I get it !
    </button>
  </div>
</dmc-ng-base-dialog>
