import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noValue',
  standalone: true,
})
export class NoValuePipe implements PipeTransform {
  transform(value: string | number | undefined): string {
    return value === undefined || value === null || value === ''
      ? '-'
      : value.toString();
  }
}
