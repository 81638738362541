import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SortUtils } from '@dmc-ng/utils';

@Injectable({
  providedIn: 'root',
})
export class SortNavigationService {
  constructor(private router: Router) {}

  navigateWithSort(col: { colId: string; sort: string }): void {
    this.router.navigate([], {
      queryParams: {
        page: 0,
        sort: col.sort
          ? SortUtils.createQueryParams({ col: col.colId, sort: col.sort })
          : undefined,
      },
      replaceUrl: true,
      queryParamsHandling: 'merge',
    });
  }
}
