<dmc-ng-base-dialog
  [inline]="false"
  (closeDialog)="data.clickOnCancel()"
>
  <dmc-ng-icon
    dialog-icon
    [color]="iconColorEnum.Gray"
    [theme]="iconThemeEnum.LightCircleOutline"
    [size]="iconSizeEnum.LG"
    icon="key"
  ></dmc-ng-icon>
  <h3 dialog-title>
    @if (send()) {
      An email has been sent to: <span>{{ email.value }}</span>
    } @else {
      Reset your password ?
    }
  </h3>
  <div
    class="content"
    dialog-content
  >
    @if (send()) {
      <p>
        An email has been sent to your address with instructions on how to reset
        your password. Please check your inbox and follow the instructions to
        complete the password reset process. If you do not receive the email
        within a few minutes, please check your spam or junk folder.
      </p>
    } @else {
      <p>
        You are about to reset your password. Please verify or enter your email
        address.
      </p>
      <dmc-ng-input
        label="Email"
        type="text"
        [formControl]="email"
        customError="Invalid email format"
      ></dmc-ng-input>
    }
  </div>

  <div dialog-actions>
    @if (send()) {
      <button
        mat-raised-button
        color="primary"
        class="medium understand"
        (click)="data.clickOnCancel()"
        [autofocus]="false"
      >
        I understand
      </button>
    } @else {
      <button
        mat-stroked-button
        color="secondary"
        class="medium button"
        (click)="data.clickOnCancel()"
        [autofocus]="false"
      >
        Cancel
      </button>
      <button
        mat-raised-button
        color="primary"
        class="medium button"
        (click)="data.clickOnReset(email.value)"
        [autofocus]="false"
        [disabled]="email.invalid"
      >
        Confirm
      </button>
    }
  </div>
</dmc-ng-base-dialog>
