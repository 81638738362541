import { Pipe, PipeTransform } from '@angular/core';
import { AccountModel } from '@dmc-ng/data-access/accounts';

@Pipe({
  name: 'accountLogo',
  standalone: true,
})
export class AccountLogoPipe implements PipeTransform {
  transform(
    accounts: AccountModel[] | undefined,
    accountId: string,
  ): string | undefined {
    return accounts
      ? accounts.find((a) => a.id === accountId)?.logoUrl
      : undefined;
  }
}
