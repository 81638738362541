import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AvatarComponent } from '@dmc-ng/ui/avatar';

@Component({
  selector: 'dmc-ng-logo-list',
  standalone: true,
  imports: [CommonModule, AvatarComponent],
  templateUrl: './logo-list.component.html',
  styleUrl: './logo-list.component.scss',
})
export class LogoListComponent implements OnInit {
  @Input() data!: { name: string; logo?: string }[];
  @Input() logoSize = 32;

  accountCount?: string;

  ngOnInit(): void {
    if (this.data.length > 4) {
      this.accountCount = `+${this.data.length - 3}`;
      this.data = this.data.slice(0, 3);
    }
  }
}
