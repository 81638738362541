import { UserModel } from '@dmc-ng/data-access/me';
import { OrganizationModel } from '@dmc-ng/data-access/organizations';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { OrganizationWithAccountModel } from '../models/organization-with-account.model';

export const dmcConsoleActions = createActionGroup({
  source: 'Dmc Console',
  events: {
    'Set Current User': props<{ user: UserModel }>(),
    'Reset Current User': emptyProps(),

    'Get Current User Policies': emptyProps(),

    'Add mapped Accounts for Organizations': props<{
      organizations: OrganizationWithAccountModel[];
    }>(),

    'Set Current Organization Id': props<{ organizationId: string }>(),
    'Reset Current Organization Id': emptyProps(),

    'Set Current Account Id': props<{ accountId: string }>(),
    'Reset Current Account Id': emptyProps(),

    'Set Current Organization Id And Account Id': props<{
      organizationId: string | undefined;
      accountId: string | undefined;
    }>(),

    'Set Detailed Current Organization': props<{
      organization: OrganizationModel;
    }>(),

    'Is allowed to navigate': props<{
      allowNavigate: boolean | undefined;
    }>(),
    'Prevent navigation': props<{
      preventNavigate: boolean;
    }>(),
    'Set form dirty state': props<{ formId: string; isDirty: boolean }>(),
    'Remove form from dirty state': props<{ formId: string }>(),

    'Reset password email send': emptyProps(),
  },
});
