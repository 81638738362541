@for (d of data; track d) {
  <dmc-ng-avatar
    [classType]="'circle'"
    [value]="d.name"
    [width]="logoSize"
    [pictureUrl]="d.logo"
    [border]="'white'"
  ></dmc-ng-avatar>
}
@if (accountCount) {
  <div
    class="fake-badge"
    [style.width.px]="logoSize"
    [style.height.px]="logoSize"
  >
    <div class="value">{{ accountCount }}</div>
  </div>
}
