import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { CountingModel } from '@dmc-ng/data-access/countings';
import { OrganizationModel } from '@dmc-ng/data-access/organizations';
import {
  ActionsCellEnum,
  ActionsCellRendererComponent,
  AvatarNameCellRendererComponent,
  CreatedByCellRendererComponent,
  DateTimeCellRendererComponent,
} from '@dmc-ng/ui/ag-grid-renderer';
import { PaginatorComponent } from '@dmc-ng/ui/paginator';
import { SortUtils } from '@dmc-ng/utils';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridOptions } from 'ag-grid-community';

import { AgGridActionDirective } from '../../directives/ag-grid-action.directive';
import {
  defaultColDef,
  defaultGridOptions,
} from '../../models/const/default-grid-options.model';
import { CountingGridModel } from '../../models/counting-grid.model';
import { PermissionEnum } from '../../models/enum/permission.enum';
import { PermissionsService } from '../../services/permissions.service';

@Component({
  selector: 'dmc-console-shared-countings-table',
  standalone: true,
  imports: [
    CommonModule,
    AgGridAngular,
    PaginatorComponent,
    AgGridActionDirective,
  ],
  templateUrl: './countings-table.component.html',
  styleUrls: ['./countings-table.component.scss'],
})
export class CountingsTableComponent implements OnInit, OnChanges {
  @Input() countings!: CountingModel[];
  @Input() currentOrganization!: OrganizationModel;
  @Input() paginatorTotal = 0;
  @Input() paginatorIndex = 0;
  @Input() loading = false;
  @Output() paginatorChange = new EventEmitter<number>();
  @Output() deleteCounting = new EventEmitter<CountingModel>();
  @Output() editCounting = new EventEmitter<CountingModel>();
  @Output() sorting = new EventEmitter<{ colId: string; sort: string }>();

  readonly permissionEnum: typeof PermissionEnum = PermissionEnum;

  gridOptions: GridOptions = {
    ...defaultGridOptions,
    domLayout: 'autoHeight',
  };

  defaultColDef: ColDef = defaultColDef;

  columnDefs: ColDef[] = [];
  countingGridData: CountingGridModel[] = [];

  frameworkComponents: Record<string, unknown> = {
    avatarNameCellComponent: AvatarNameCellRendererComponent,
    createdByCellComponent: CreatedByCellRendererComponent,
    datetimeCellComponent: DateTimeCellRendererComponent,
    actionsCellComponent: ActionsCellRendererComponent,
  };

  constructor(private permissionService: PermissionsService) {}

  ngOnInit(): void {
    this.columnDefs = [
      {
        headerName: 'Name',
        field: 'name',
        editable: false,
        cellRenderer: 'avatarNameCellComponent',
        cellRendererParams: (avatarParams: {
          data: CountingGridModel;
        }): any => ({
          displayName: avatarParams.data.name,
          pictureUrl: avatarParams.data.accountName.picture,
        }),
      },
      {
        headerName: 'Versions',
        field: 'numberVersions',
        editable: false,
        sortable: false,
      },
      {
        headerName: 'Created by',
        field: 'user.displayName',
        editable: false,
        sortable: false,
        cellRenderer: 'createdByCellComponent',
        cellRendererParams: (createdByParams: {
          data: CountingGridModel;
        }): any => ({
          displayName: createdByParams.data.user.displayName,
          pictureUrl: createdByParams.data.user.pictureUrl,
        }),
      },
      {
        headerName: 'Created at',
        field: 'createAt',
        editable: false,
        cellRenderer: 'datetimeCellComponent',
        cellRendererParams: (createdAtParams: {
          data: CountingGridModel;
        }): any => ({
          createdAt: createdAtParams.data.createAt,
        }),
      },
      ...(this.permissionService.hasPermission(PermissionEnum.CountingWrite)
        ? [
            {
              editable: false,
              sortable: false,
              resizable: false,
              cellRenderer: 'actionsCellComponent',
              cellRendererParams: (): any => ({
                onClick: this.handleActionClick.bind(this),
                canDelete: true,
                canEdit: true,
                canCancel: false,
              }),
            },
          ]
        : []),
    ];
  }

  ngOnChanges(): void {
    this.countingGridData = this.countings.map((counting) => ({
      ...counting,
      accountName: {
        name:
          this.currentOrganization.accounts?.find(
            (acc) => acc.id === counting.accountId,
          )?.name ?? '',
        picture:
          this.currentOrganization.accounts?.find(
            (acc) => acc.id === counting.accountId,
          )?.logoUrl ?? undefined,
      },
    }));
  }

  onSortChanged(event: any): void {
    const sortModel = SortUtils.getColumnSort(event);

    if (sortModel.length > 0) {
      sortModel.forEach((col: { colId: string; sort: string }) => {
        this.sorting.emit(col);
      });
    }
  }

  handleActionClick(
    actionType: ActionsCellEnum,
    rowData: CountingGridModel,
  ): void {
    switch (actionType) {
      case ActionsCellEnum.Edit:
        this.editCounting.emit(rowData);
        break;
      case ActionsCellEnum.Delete:
        this.deleteCounting.emit(rowData);
        break;
      default:
        break;
    }
  }
}
