<dmc-ng-base-dialog
  [inline]="false"
  (closeDialog)="data.clickOnCancel()"
>
  <dmc-ng-icon
    dialog-icon
    [color]="iconColorEnum.Warning"
    [theme]="iconThemeEnum.LightCircleOutline"
    [size]="iconSizeEnum.LG"
    icon="copy-06"
  ></dmc-ng-icon>
  <h3 dialog-title>Clipboard settings</h3>

  <ng-container dialog-content>
    @if (data.userAgent.browser.chromium) {
      <p>
        Your browser is Chromium-based. To allow clipboard access for this
        domain, please copy the following link into your address bar:
      </p>
      <div class="link-input">
        <dmc-ng-input
          type="text"
          class="full-width"
          [formControl]="clipboardSettingUrl"
        ></dmc-ng-input>
      </div>
    } @else {
      <p>
        It seems that your browser is not Chromium-based or does not support
        directly opening settings.
      </p>
      <p>Please follow these instructions to modify your browser settings:</p>
      <div class="settings-info">
        <p>1. Open your browser settings.</p>
        <p>2. Locate the section dedicated to permissions or site settings.</p>
        <p>3. Find the setting related to clipboard access.</p>
        <p>4. Allow clipboard access for this domain.</p>
      </div>
    }
  </ng-container>

  <div dialog-actions>
    <button
      mat-stroked-button
      color="secondary"
      class="medium button"
      (click)="data.clickOnCancel()"
      [autofocus]="false"
    >
      Cancel
    </button>
  </div>
</dmc-ng-base-dialog>
