import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pricePerSms',
  standalone: true,
})
export class PricePerSmsPipe implements PipeTransform {
  transform(value: number | undefined, withText = false): string | undefined {
    if (value) {
      const price = value / 1000;
      return withText ? `${price}€ per message` : `${price}€/SMS`;
    }
    return undefined;
  }
}
