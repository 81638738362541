import { selectOrganizations } from '@dmc-ng/data-access/organizations';
import { getRouterSelectors } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  DMC_CONSOLE_FEATURE_KEY,
  DmcConsoleState,
} from './dmc-console.reducers';

export const selectDmcConsoleState = createFeatureSelector<DmcConsoleState>(
  DMC_CONSOLE_FEATURE_KEY,
);
export const selectUser = createSelector(
  selectDmcConsoleState,
  (state) => state.user,
);
export const selectMesaReady = createSelector(
  selectDmcConsoleState,
  (state) => state.mesaReady,
);
export const selectIsSuperAdmin = createSelector(
  selectDmcConsoleState,
  (state) => state.isSuperAdmin,
);
export const selectUserPolicies = createSelector(
  selectDmcConsoleState,
  (state) => state.policies,
);
export const selectUserRole = createSelector(
  selectDmcConsoleState,
  (state) => state.role,
);
export const selectCurrentAccountId = createSelector(
  selectDmcConsoleState,
  (state) => state.currentAccountId,
);
export const selectCurrentOrganizationId = createSelector(
  selectDmcConsoleState,
  (state) => state.currentOrganizationId,
);
export const selectCurrentOrganizationIdAndAccountId = createSelector(
  selectCurrentOrganizationId,
  selectCurrentAccountId,
  (currentOrganizationId, currentAccountId) => ({
    currentOrganizationId,
    currentAccountId,
  }),
);

export const selectDetailedCurrentOrganization = createSelector(
  selectDmcConsoleState,
  (state) => state.detailedCurrentOrganization,
);

export const selectCurrentOrFirstOrganizationId = createSelector(
  selectOrganizations,
  selectCurrentOrganizationId,
  selectCurrentAccountId,
  (
    organizations,
    organizationId,
    accountId,
  ): { organizationId?: string; accountId?: string } => {
    if (!organizationId && organizations.length) {
      organizationId = organizations[0].id;
      accountId = accountId ?? (organizations[0].accounts || [])[0]?.id;
    }

    return { organizationId, accountId };
  },
);

export const selectHasRightToAccess = (permission: string) =>
  createSelector(
    selectCurrentOrganizationId,
    selectUserPolicies,
    selectIsSuperAdmin,
    (organizationId, policies, isSuperAdmin) => {
      if (isSuperAdmin || !permission) {
        return true;
      }

      if (!policies || policies.length === 0) {
        return false;
      }

      if (organizationId) {
        const filteredPolicies = policies.filter(
          (policy) => policy.organizationId === organizationId,
        );
        return filteredPolicies.some((policy) =>
          policy.permissions.includes(permission),
        );
      } else {
        return policies.some((policy) =>
          policy.permissions.includes(permission),
        );
      }
    },
  );

export const selectHasPermissionInSomeOrganizations = (permission: string) =>
  createSelector(
    selectUserPolicies,
    selectIsSuperAdmin,
    (policies, isSuperAdmin) => {
      if (isSuperAdmin || !permission) return true;

      return (policies || []).some(
        (pol) => pol.organizationId && pol.permissions.includes(permission),
      );
    },
  );

export const selectOrganizationsWithAccounts = createSelector(
  selectOrganizations,
  (organizations) =>
    organizations.filter((org) => org.accounts && org.accounts.length > 0),
);

export const selectAllowNavigation = createSelector(
  selectDmcConsoleState,
  (state: DmcConsoleState) => state.allowNavigate,
);

export const selectPreventNavigation = createSelector(
  selectDmcConsoleState,
  (state: DmcConsoleState) => state.preventNavigate,
);

export const { selectUrl } = getRouterSelectors();
