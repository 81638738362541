import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Inject,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { BaseDialogComponent } from '@dmc-ng/ui/dialogs';
import { EMAIL_REGEX, InputComponent } from '@dmc-ng/ui/form';
import {
  IconSizeEnum,
  IconThemeEnum,
  IconColorEnum,
  IconComponent,
} from '@dmc-ng/ui/icon';
import { Actions, ofType } from '@ngrx/effects';

import { ResetPasswordDialogModel } from './models/reset-password-dialog.model';
import { dmcConsoleActions } from '../../../state/dmc-console.actions';

@Component({
  selector: 'dmc-console-login-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    InputComponent,
    FormsModule,
    ReactiveFormsModule,
    IconComponent,
    BaseDialogComponent,
  ],
  standalone: true,
})
export class ResetPasswordDialogComponent implements OnInit {
  readonly iconSizeEnum: typeof IconSizeEnum = IconSizeEnum;
  readonly iconThemeEnum: typeof IconThemeEnum = IconThemeEnum;
  readonly iconColorEnum: typeof IconColorEnum = IconColorEnum;

  email: FormControl<string> = new FormControl('', {
    validators: [Validators.required, Validators.pattern(EMAIL_REGEX)],
    nonNullable: true,
  });

  send = signal(false);

  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ResetPasswordDialogModel,
    private actions$: Actions,
  ) {
    if (data.email) {
      this.email.patchValue(data.email);
    }
  }

  ngOnInit(): void {
    this.actions$
      .pipe(
        ofType(dmcConsoleActions.resetPasswordEmailSend),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.send.set(true);
      });
    if (this.data.shouldBeDisabled) {
      this.email.disable();
    }
  }
}
