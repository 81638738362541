import { APIResponse, DeepPartial, UploadLogoModel } from '@dmc-ng/data-access';
import { props, createActionGroup, emptyProps } from '@ngrx/store';

import { SetUserRequestStoreModel } from '../models/set-user-request-store.model';
import { ScopedUserModel } from '../models/user-api.model';
import { UserStoreModel } from '../models/user-store.model';

export const userActions = createActionGroup({
  source: 'User',
  events: {
    'Set Current User': props<{ user: UserStoreModel }>(),
    'Reset Current User': emptyProps(),

    'Create User': props<{ user: SetUserRequestStoreModel }>(),
    'User created successfully': props<{
      user: APIResponse<ScopedUserModel>;
    }>(),
    'User failed to be created': props<{ error: unknown }>(),

    'Get User details': props<{ userId: string }>(),
    'User details retrieved successfully': props<{
      user: APIResponse<ScopedUserModel>;
    }>(),
    'User details failed to be retrieved': props<{ error: unknown }>(),

    'Update User': props<{
      userId: string;
      user: DeepPartial<ScopedUserModel>;
      logo?: UploadLogoModel;
    }>(),
    'User updated successfully': props<{
      user: ScopedUserModel;
      logo?: UploadLogoModel;
    }>(),
    'User failed to be updated': props<{ error: unknown }>(),

    'Delete User': props<{ userId: string; organizationId?: string }>(),
    'User delete successfully': props<{ userId: string }>(),
    'User failed to be deleted': props<{ error: unknown }>(),

    'Get Users with search': props<{
      organizationId?: string;
      query?: string;
      limit?: number;
      withPhone?: boolean;
      withSU?: boolean;
    }>(),

    'Users searched successfully': props<{
      users: any;
    }>(),
    'Users failed to be searched': props<{ error: unknown }>(),

    'Add logo for User': props<{
      userId: string;
      uploadLogo: UploadLogoModel;
    }>(),
    'User logo added successfully': props<{ user: ScopedUserModel }>(),
    'User logo failed to be added': props<{ error: unknown }>(),
  },
});
