import { Component } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TooltipColorEnum, TooltipPositionEnum } from '@dmc-ng/ui/tooltip';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

@Component({
  standalone: true,
  imports: [MatChipsModule, MatTooltipModule],
  templateUrl: './chip-editable-cell.component.html',
  styleUrl: './chip-editable-cell.component.scss',
})
export class ChipEditableCellRendererComponent
  implements ICellRendererAngularComp
{
  readonly tooltipPositionEnum: typeof TooltipPositionEnum =
    TooltipPositionEnum;
  readonly tooltipColorEnum: typeof TooltipColorEnum = TooltipColorEnum;

  cellValue?: string | number;

  agInit(params: ICellRendererParams): void {
    this.cellValue = params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    this.cellValue = params.value;
    return true;
  }
}
