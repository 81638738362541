import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { LoadingStateEnum } from '@dmc-ng/data-access';
import { loadingOrganizations } from '@dmc-ng/data-access/organizations';
import { Store } from '@ngrx/store';
import { combineLatest, filter, map } from 'rxjs';

import { selectMesaReady } from '../state/dmc-console.selectors';

export const appReadyGuard: CanActivateFn = () => {
  const store: Store = inject(Store);

  return combineLatest([
    store
      .select(loadingOrganizations)
      .pipe(filter((loading) => loading === LoadingStateEnum.Done)),
    store.select(selectMesaReady).pipe(filter((ready) => !!ready)),
  ]).pipe(map(() => true));
};
