import { ActivatedRouteSnapshot } from '@angular/router';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { filter, map, Observable, pipe, UnaryFunction } from 'rxjs';

import {
  ACCOUNT_MANAGEMENT,
  ACCOUNT_MANAGEMENT_ACCOUNTS_SECTION,
  ACCOUNT_MANAGEMENT_LIST,
  ACCOUNT_MANAGEMENT_MEMBERS_SECTION,
  ACCOUNT_MANAGEMENT_MEMBERS_TAB,
  ACCOUNT_MANAGEMENT_ORGANIZATIONS_TAB,
  ACCOUNT_MANAGEMENT_SMS_SECTION,
  CAMPAIGN,
  CAMPAIGN_LIST,
  DATA_EDITOR,
  DATA_EDITOR_LIST,
  DATA_EDITOR_POI_DATA_LIST,
  MEDIA_PLANNING,
  MEDIA_PLANNING_LIST,
  OVERVIEW,
  OVERVIEW_DETAILS,
  PROFILE,
} from '../models/url.const';
import { selectUrl } from '../state/dmc-console.selectors';

export abstract class RoutingUtils {
  static readonly pages = {
    get error404(): string {
      return '/404';
    },
    get error403(): string {
      return '/403';
    },
    get accountManagement(): string {
      return '/account-management/all';
    },
  };

  static readonly routeParams = {
    get organizationId(): string {
      return 'organizationId';
    },
    get campaignId(): string {
      return 'campaignId';
    },
    get countingId(): string {
      return 'countingId';
    },
    get dataImportId(): string {
      return 'dataImportId';
    },
  };

  static readonly queryParams = {
    get accountId(): string {
      return 'account';
    },
    get page(): string {
      return 'page';
    },
    get counting(): string {
      return 'counting';
    },
    get version(): string {
      return 'version';
    },
  };

  static matchRegex = <T>(
    store: Store,
    regexp: RegExp,
  ): UnaryFunction<Observable<T>, Observable<T>> =>
    pipe(
      concatLatestFrom(() => [store.pipe(select(selectUrl))]),
      filter(([, url]) => regexp.test(url)),
      map(([input]) => input),
    );

  // Account management
  static isMembersTab = <T>(
    store: Store,
  ): UnaryFunction<Observable<T>, Observable<T>> =>
    RoutingUtils.matchRegex(store, ACCOUNT_MANAGEMENT_MEMBERS_TAB);

  static isMembersSection = <T>(
    store: Store,
  ): UnaryFunction<Observable<T>, Observable<T>> =>
    RoutingUtils.matchRegex(store, ACCOUNT_MANAGEMENT_MEMBERS_SECTION);

  static isAccountsSection = <T>(
    store: Store,
  ): UnaryFunction<Observable<T>, Observable<T>> =>
    RoutingUtils.matchRegex(store, ACCOUNT_MANAGEMENT_ACCOUNTS_SECTION);

  static isAccountsOrSMSSection = <T>(
    store: Store,
  ): UnaryFunction<Observable<T>, Observable<T>> =>
    pipe(
      concatLatestFrom(() => [store.pipe(select(selectUrl))]),
      filter(
        ([, url]) =>
          ACCOUNT_MANAGEMENT_ACCOUNTS_SECTION.test(url) ||
          ACCOUNT_MANAGEMENT_SMS_SECTION.test(url),
      ),
      map(([input]) => input),
    );

  static isOrganizationsTab = <T>(
    store: Store,
  ): UnaryFunction<Observable<T>, Observable<T>> =>
    RoutingUtils.matchRegex(store, ACCOUNT_MANAGEMENT_ORGANIZATIONS_TAB);

  static isAccountManagement = <T>(
    store: Store,
  ): UnaryFunction<Observable<T>, Observable<T>> =>
    RoutingUtils.matchRegex(store, ACCOUNT_MANAGEMENT);

  static isAccountManagementList = <T>(
    store: Store,
  ): UnaryFunction<Observable<T>, Observable<T>> =>
    RoutingUtils.matchRegex(store, ACCOUNT_MANAGEMENT_LIST);

  static isNotManagementList = <T>(
    store: Store,
  ): UnaryFunction<Observable<T>, Observable<T>> =>
    pipe(
      concatLatestFrom(() => [store.pipe(select(selectUrl))]),
      filter(([, url]) => !ACCOUNT_MANAGEMENT_LIST.test(url)),
      map(([input]) => input),
    );

  // Campaign
  static isCampaign = <T>(
    store: Store,
  ): UnaryFunction<Observable<T>, Observable<T>> =>
    RoutingUtils.matchRegex(store, CAMPAIGN);

  static isCampaignList = <T>(
    store: Store,
  ): UnaryFunction<Observable<T>, Observable<T>> =>
    RoutingUtils.matchRegex(store, CAMPAIGN_LIST);

  // Media planning
  static isMediaPlanning = <T>(
    store: Store,
  ): UnaryFunction<Observable<T>, Observable<T>> =>
    RoutingUtils.matchRegex(store, MEDIA_PLANNING);

  static isMediaPlanningList = <T>(
    store: Store,
  ): UnaryFunction<Observable<T>, Observable<T>> =>
    RoutingUtils.matchRegex(store, MEDIA_PLANNING_LIST);

  static isCampaignOrMediaPlanning = <T>(
    store: Store,
  ): UnaryFunction<Observable<T>, Observable<T>> =>
    pipe(
      concatLatestFrom(() => [store.pipe(select(selectUrl))]),
      filter(([, url]) => CAMPAIGN.test(url) || MEDIA_PLANNING.test(url)),
      map(([input]) => input),
    );

  static isNotCampaignAndMediaPlanning = <T>(
    store: Store,
  ): UnaryFunction<Observable<T>, Observable<T>> =>
    pipe(
      concatLatestFrom(() => [store.pipe(select(selectUrl))]),
      filter(([, url]) => !CAMPAIGN.test(url) && !MEDIA_PLANNING.test(url)),
      map(([input]) => input),
    );

  // Overview
  static isOverview = <T>(
    store: Store,
  ): UnaryFunction<Observable<T>, Observable<T>> =>
    RoutingUtils.matchRegex(store, OVERVIEW);

  static isOverviewDetails = <T>(
    store: Store,
  ): UnaryFunction<Observable<T>, Observable<T>> =>
    RoutingUtils.matchRegex(store, OVERVIEW_DETAILS);

  // Data editor
  static isDataEditor = <T>(
    store: Store,
  ): UnaryFunction<Observable<T>, Observable<T>> =>
    RoutingUtils.matchRegex(store, DATA_EDITOR);
  static isDataEditorList = <T>(
    store: Store,
  ): UnaryFunction<Observable<T>, Observable<T>> =>
    RoutingUtils.matchRegex(store, DATA_EDITOR_LIST);
  static isDataEditorDetails = <T>(
    store: Store,
  ): UnaryFunction<Observable<T>, Observable<T>> =>
    RoutingUtils.matchRegex(store, DATA_EDITOR_POI_DATA_LIST);

  // Profile
  static isProfile = <T>(
    store: Store,
  ): UnaryFunction<Observable<T>, Observable<T>> =>
    RoutingUtils.matchRegex(store, PROFILE);

  // CREATE ROUTE
  static buildUrlFromRoute(snapshot: ActivatedRouteSnapshot): string[] {
    const urlSegments: string[] = [];
    this.accumulateParentSegments(snapshot, urlSegments);
    this.processSnapshot(snapshot, urlSegments);
    return urlSegments;
  }

  // UPDATE ROUTE PARAMS
  static updateRouteParams(
    route: ActivatedRouteSnapshot,
    newParams: { [key: string]: string },
    hasAccount = true,
  ): string[] {
    const urlSegments: string[] = [];
    this.processSnapshot(route, urlSegments, newParams, hasAccount);
    return urlSegments;
  }

  private static accumulateParentSegments(
    snapshot: ActivatedRouteSnapshot,
    urlSegments: string[],
  ): void {
    let currentSnapshot: ActivatedRouteSnapshot | null = snapshot.parent;

    while (currentSnapshot) {
      if (currentSnapshot.routeConfig?.path) {
        const path = this.processPath(
          currentSnapshot.routeConfig.path,
          currentSnapshot,
          {},
        );
        if (path !== '') {
          urlSegments.unshift(path);
        }
      }
      currentSnapshot = currentSnapshot.parent;
    }
  }

  private static processSnapshot(
    currentSnapshot: ActivatedRouteSnapshot,
    urlSegments: string[],
    newParams: { [key: string]: string } = {},
    hasAccount = true,
  ): void {
    if (currentSnapshot.routeConfig?.path) {
      const path = this.processPath(
        currentSnapshot.routeConfig.path,
        currentSnapshot,
        newParams,
        hasAccount,
      );
      if (path !== '') {
        urlSegments.push(path);
      }
    }

    currentSnapshot.children.forEach((childSnapshot) => {
      this.processSnapshot(childSnapshot, urlSegments, newParams, hasAccount);
    });
  }

  private static processPath(
    path: string,
    snapshot: ActivatedRouteSnapshot,
    newParams: { [key: string]: string },
    hasAccount = true,
  ): string {
    if (path.startsWith('edit/')) {
      return '';
    }

    if (!hasAccount && path.startsWith('create')) {
      return '';
    }

    const paramMatch = path.match(/:([a-zA-Z0-9]+)/g);

    if (paramMatch) {
      paramMatch.forEach((param) => {
        const paramName = param.substring(1);
        const paramValue =
          newParams[paramName] || this.getParamValue(snapshot, paramName);
        path = path.replace(`:${paramName}`, paramValue || '');
      });
    }
    return path;
  }

  private static getParamValue(
    snapshot: ActivatedRouteSnapshot,
    paramName: string,
  ): string | null {
    return snapshot.paramMap.get(paramName);
  }
}
