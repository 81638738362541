import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-enterprise';

interface EditableCellParams extends ICellEditorParams {
  validators?: ValidatorFn[];
  inputType?: string;
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
  ],
  templateUrl: './editable-cell-validator.component.html',
  styleUrls: ['./editable-cell-validator.component.scss'],
})
export class EditableCellValidatorComponent implements ICellEditorAngularComp {
  control: FormControl;
  inputType = 'text';

  constructor(private fb: FormBuilder) {
    this.control = this.fb.control('');
    this.control.valueChanges.subscribe({
      next: () => {
        if (this.control.invalid) {
          this.control.markAsDirty();
          this.control.markAsTouched();
        }
      },
    });
  }

  agInit(params: EditableCellParams): void {
    this.inputType = params.inputType ?? 'text';
    const validators: ValidatorFn[] = params.validators || [];

    this.control.setValidators(validators);
    this.control.setValue(params.value);
    this.control.updateValueAndValidity();
  }

  getValue(): number {
    const parsedValue = Number(this.control.value);
    return isNaN(parsedValue) ? 0 : parsedValue;
  }

  isCancelAfterEnd(): boolean {
    return this.control.invalid;
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' && this.control.invalid) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
