export enum StateLabelEnum {
  All = 'All',
  InProgress = 'In progress',
  Pending = 'Pending',
  Draft = 'Draft',
  Finished = 'Finished',
  Ready = 'Ready',
  PendingApproval = 'Pending approval',
  Error = 'Error',
}
