import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnChanges,
} from '@angular/core';
import { CampaignMetricModel } from '@dmc-ng/data-access/campaign-reportings';
import { CampaignStateEnum } from '@dmc-ng/data-access/campaigns';
import { OrganizationModel } from '@dmc-ng/data-access/organizations';
import {
  AvatarNameCellRendererComponent,
  DateTimeCellRendererComponent,
  StatusCellRendererComponent,
} from '@dmc-ng/ui/ag-grid-renderer';
import { PaginatorComponent } from '@dmc-ng/ui/paginator';
import { SortUtils } from '@dmc-ng/utils';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community';

import { AgGridActionDirective } from '../../directives/ag-grid-action.directive';
import { CampaignMetricGridModel } from '../../models/campaign-metric-grid.model';
import {
  EquivalenceColor,
  EquivalenceName,
} from '../../models/campaign-state.const';
import {
  defaultColDef,
  defaultGridOptions,
} from '../../models/const/default-grid-options.model';
import { PermissionEnum } from '../../models/enum/permission.enum';
import { StateLabelEnum } from '../../models/enum/state-label.enum';

@Component({
  selector: 'dmc-console-shared-campaigns-table',
  standalone: true,
  imports: [
    CommonModule,
    AgGridAngular,
    PaginatorComponent,
    AgGridActionDirective,
  ],
  templateUrl: './campaigns-table.component.html',
  styleUrls: ['./campaigns-table.component.scss'],
})
export class CampaignsTableComponent implements OnInit, OnChanges {
  @Input() campaigns!: CampaignMetricModel[];
  @Input() currentOrganization!: OrganizationModel;
  @Input() paginatorTotal = 0;
  @Input() paginatorIndex = 0;
  @Input() loading = false;
  @Input() permission?: PermissionEnum;
  @Output() paginatorChange = new EventEmitter<number>();
  @Output() seeDetails = new EventEmitter<CampaignMetricModel>();
  @Output() sorting = new EventEmitter<{ colId: string; sort: string }>();

  gridOptions: GridOptions = {
    ...defaultGridOptions,
    domLayout: 'autoHeight',
  };

  defaultColDef = defaultColDef;

  columnDefs: ColDef[] = [];
  campaignMetricGridData: CampaignMetricGridModel[] = [];

  frameworkComponents: Record<string, unknown> = {
    avatarNameCellComponent: AvatarNameCellRendererComponent,
    datetimeCellComponent: DateTimeCellRendererComponent,
    stateCellComponent: StatusCellRendererComponent,
  };

  ngOnInit(): void {
    this.columnDefs = [
      {
        headerName: 'Name',
        field: 'campaignName',
        editable: false,
        cellRenderer: 'avatarNameCellComponent',
        cellRendererParams: (avatarParams: {
          data: CampaignMetricGridModel;
        }): any => ({
          displayName: avatarParams.data.campaignName,
          onlyName: true,
        }),
      },
      {
        headerName: 'Advertiser',
        field: 'account.name',
        sortable: false,
        editable: false,
        cellRenderer: 'avatarNameCellComponent',
        cellRendererParams: (avatarParams: {
          data: CampaignMetricGridModel;
        }): any => ({
          displayName: avatarParams.data.account.name,
          pictureUrl: avatarParams.data.account.logoUrl,
        }),
      },
      {
        headerName: 'Scheduled at',
        field: 'scheduleDate',
        editable: false,
        cellRenderer: 'datetimeCellComponent',
        cellRendererParams: (createdAtParams: {
          data: CampaignMetricGridModel;
        }): any => ({
          createdAt: createdAtParams.data.scheduleDate,
        }),
      },
      {
        headerName: 'Text messages sent',
        field: 'sent',
        editable: false,
        sortable: false,
        cellClass: 'metric',
        valueFormatter: (params: { data: CampaignMetricGridModel }): string =>
          `${params.data.sent}`,
      },
      {
        headerName: 'Clicks',
        field: 'clicked',
        cellClass: 'metric',
        editable: false,
        sortable: false,
        valueFormatter: (params: { data: CampaignMetricGridModel }): string =>
          `${params.data.clicked}`,
      },
      {
        headerName: 'CTR',
        field: 'ctr',
        cellClass: 'metric',
        editable: false,
        sortable: false,
        valueFormatter: (params: { data: CampaignMetricGridModel }): string =>
          `${(params.data.ctr * 100).toFixed(2)}%`,
      },
      {
        headerName: 'Status',
        field: 'status.name',
        editable: false,
        resizable: false,
        cellRenderer: 'stateCellComponent',
        cellRendererParams: (stateParams: {
          data: CampaignMetricGridModel;
        }): any => ({
          state: stateParams.data.status.name,
          color: stateParams.data.status.color,
        }),
      },
    ];
  }

  ngOnChanges(): void {
    this.campaignMetricGridData = this.campaigns.map((campaign) => ({
      ...campaign,
      status: {
        color: this.campaignStateToColor(campaign.campaignState),
        name: this.campaignStateToName(campaign.campaignState),
      },
    }));
  }

  onSortChanged(event: any): void {
    const sortModel = SortUtils.getColumnSort(event);

    if (sortModel.length > 0) {
      sortModel.forEach((col: { colId: string; sort: string }) => {
        this.sorting.emit(col);
      });
    }
  }

  clickOnRow(event: RowClickedEvent): void {
    this.seeDetails.emit(event.data);
  }

  private campaignStateToColor(value: CampaignStateEnum): string {
    return EquivalenceColor[value] || '#D0D5DD';
  }

  private campaignStateToName(value: CampaignStateEnum): string {
    return EquivalenceName[value] || StateLabelEnum.Error;
  }
}
