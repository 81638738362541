import {
  OverviewKPIModel,
  ReportingModel,
  SMSModel,
} from '@dmc-ng/data-access/campaign-reportings';
import { GaugeModel, KpiModel } from '@dmc-ng/ui/kpi';

export class ReportingUtils {
  static convertReportingToKpiModel(reporting: ReportingModel): KpiModel {
    return {
      sent: reporting.sms.sent,
      stopRate:
        reporting.sms.blocked === 0
          ? 0
          : (reporting.sms.blocked / reporting.sms.delivered) * 100,
      success: reporting.kpi.success * 100,
      ctr: reporting.kpi.ctr * 100,
      gauges: this.createGauge(reporting.sms),
    };
  }

  static convertOverviewKPIModelToKpiModel(kpi: OverviewKPIModel): KpiModel {
    const successRate = kpi.sent === 0 ? 0 : (kpi.delivered / kpi.sent) * 100;
    return {
      sent: kpi.sent,
      stopRate: kpi.blocked === 0 ? 0 : (kpi.blocked / kpi.delivered) * 100,
      success: successRate,
      ctr: kpi.ctr * 100,
      gauges: this.createGauge(kpi),
    };
  }

  static createGauge(sms: SMSModel): GaugeModel[] {
    return [
      {
        label: 'SMS received',
        type: 'success',
        value: sms.delivered,
        progress: (sms.delivered / sms.sent) * 100,
      },
      {
        label: 'Link clicked',
        type: 'success',
        value: sms.clicked,
        progress: (sms.clicked / sms.delivered) * 100,
      },
      {
        label: 'Network error',
        type: 'error',
        value: sms.networkError,
        progress: (sms.networkError / sms.sent) * 100,
      },
      {
        label: 'Invalid number',
        type: 'error',
        value: sms.invalidNumber,
        progress: (sms.invalidNumber / sms.sent) * 100,
      },
      {
        label: 'Expired',
        type: 'error',
        value: sms.expired,
        progress: (sms.expired / sms.sent) * 100,
      },
      {
        label: 'Blacklisted',
        type: 'error',
        value: sms.blacklisted,
        progress: (sms.blacklisted / sms.sent) * 100,
      },
      {
        label: 'STOP',
        type: 'error',
        value: sms.blocked,
        progress: (sms.blocked / sms.delivered) * 100,
      },
    ];
  }
}
