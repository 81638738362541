import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NumberFormatPipe } from '@dmc-ng/ui/pipes';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

interface DualValueCellParams extends ICellRendererParams {
  getMaxPotential: (params: DualValueCellParams) => number;
  getMaxPrice: (params: DualValueCellParams) => number;
}

@Component({
  standalone: true,
  imports: [CommonModule, NumberFormatPipe],
  templateUrl: './dual-value-cell.component.html',
  styleUrls: ['./dual-value-cell.component.scss'],
})
export class DualValueCellComponent implements ICellRendererAngularComp {
  maxPotential!: number;
  maxPrice!: number;

  agInit(params: DualValueCellParams): void {
    this.maxPotential = params.getMaxPotential(params);
    this.maxPrice = params.getMaxPrice(params);
  }

  refresh(params: DualValueCellParams): boolean {
    this.maxPotential = params.getMaxPotential(params);
    this.maxPrice = params.getMaxPrice(params);
    return false;
  }
}
