import {
  UserMePolicyModel,
  UserModel,
  meActions,
} from '@dmc-ng/data-access/me';
import { OrganizationModel } from '@dmc-ng/data-access/organizations';
import { Action, createReducer, on } from '@ngrx/store';

import { dmcConsoleActions } from './dmc-console.actions';

export const DMC_CONSOLE_FEATURE_KEY = 'dmc-console';

export interface DmcConsoleState {
  mesaReady: boolean;

  user?: UserModel;
  policies?: UserMePolicyModel[];
  isSuperAdmin?: boolean;
  role?: string;
  currentAccountId?: string;
  data?: any;
  currentOrganizationId?: string;
  allowNavigate: boolean | undefined;
  preventNavigate: boolean;
  formsDirtyState: { [formId: string]: boolean };
  detailedCurrentOrganization?: OrganizationModel;
}

export const initialDmcConsoleState: DmcConsoleState = {
  mesaReady: false,
  allowNavigate: undefined,
  preventNavigate: false,
  formsDirtyState: {},
};

const reducer = createReducer(
  initialDmcConsoleState,
  on(dmcConsoleActions.setCurrentUser, (state, { user }) => ({
    ...state,
    user: user,
  })),
  on(dmcConsoleActions.resetCurrentUser, (state) => ({
    ...state,
    user: undefined,
    mesaReady: false,
    isSuperAdmin: false,
    policies: undefined,
  })),
  on(
    meActions.mePoliciesRetrievedSuccessfully,
    (state, { policies, superAdmin, role }) => ({
      ...state,
      isSuperAdmin: superAdmin,
      policies,
      role,
      mesaReady: true,
    }),
  ),
  on(meActions.mePoliciesFailedToBeRetrieved, (state) => ({
    ...state,
    isSuperAdmin: false,
    policies: undefined,
    role: undefined,
    mesaReady: false,
  })),
  on(dmcConsoleActions.setCurrentAccountId, (state, { accountId }) => ({
    ...state,
    currentAccountId: accountId,
  })),
  on(dmcConsoleActions.resetCurrentAccountId, (state) => ({
    ...state,
    currentAccountId: undefined,
  })),
  on(
    dmcConsoleActions.setCurrentOrganizationId,
    (state, { organizationId }) => ({
      ...state,
      currentOrganizationId: organizationId,
    }),
  ),
  on(dmcConsoleActions.resetCurrentOrganizationId, (state) => ({
    ...state,
    currentOrganizationId: undefined,
  })),
  on(
    dmcConsoleActions.setCurrentOrganizationIdAndAccountId,
    (state, { organizationId, accountId }) => ({
      ...state,
      currentOrganizationId: organizationId,
      currentAccountId: accountId,
    }),
  ),
  on(
    dmcConsoleActions.setDetailedCurrentOrganization,
    (state, { organization }) => ({
      ...state,
      detailedCurrentOrganization: organization,
    }),
  ),
  on(dmcConsoleActions.isAllowedToNavigate, (state, { allowNavigate }) => ({
    ...state,
    allowNavigate,
  })),
  on(dmcConsoleActions.preventNavigation, (state, { preventNavigate }) => ({
    ...state,
    preventNavigate,
  })),

  on(dmcConsoleActions.removeFormFromDirtyState, (state, { formId }) => {
    const remainingForms = { ...state.formsDirtyState };
    delete remainingForms[formId];

    const isAnyFormDirty = Object.values(remainingForms).some((dirty) => dirty);

    return {
      ...state,
      formsDirtyState: remainingForms,
      preventNavigate: isAnyFormDirty,
    };
  }),
  on(dmcConsoleActions.setFormDirtyState, (state, { formId, isDirty }) => {
    const updatedFormsDirtyState = {
      ...state.formsDirtyState,
      [formId]: isDirty,
    };
    const isAnyFormDirty = Object.values(updatedFormsDirtyState).some(
      (dirty) => dirty,
    );

    return {
      ...state,
      formsDirtyState: updatedFormsDirtyState,
      preventNavigate: isAnyFormDirty,
    };
  }),
);

export function dmcConsoleReducer(
  state: DmcConsoleState | undefined,
  action: Action,
): DmcConsoleState {
  return reducer(state, action);
}
