import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

import { ActionsCellEnum } from './enum/actions-cell.enum';

interface ActionsParams extends ICellRendererParams {
  onClick: (type: 'delete' | 'edit' | 'cancel' | 'see', rowData: any) => void;
  shouldAccessAllButton: boolean;
  canEdit: boolean;
  canDelete: boolean;
  canCancel: boolean;
  canSee: boolean;
}

@Component({
  standalone: true,
  imports: [MatIconModule, CommonModule, MatButtonModule],
  templateUrl: './actions-cell.component.html',
  styleUrl: './actions-cell.component.scss',
})
export class ActionsCellRendererComponent implements ICellRendererAngularComp {
  readonly actionsCellEnum: typeof ActionsCellEnum = ActionsCellEnum;

  params!: ActionsParams;
  canEdit!: boolean;
  canDelete!: boolean;
  canCancel!: boolean;
  canSee!: boolean;

  agInit(params: ActionsParams): void {
    this.params = params;
    this.canEdit = params.canEdit;
    this.canDelete = params.canDelete;
    this.canCancel = params.canCancel;
    this.canSee = params.canSee;
  }

  refresh(params: ActionsParams): boolean {
    return true;
  }

  onClick(actionType: ActionsCellEnum, event: Event): void {
    event.stopPropagation();
    this.params.onClick(actionType, this.params.data);
  }
}
