<div class="cell-container d-flex align-items-center h-100 w-100">
  <div class="logo-name">
    @if (!onlyName) {
      <dmc-ng-avatar
        [classType]="'circle'"
        [value]="displayName"
        [width]="40"
        [pictureUrl]="pictureUrl"
      ></dmc-ng-avatar>
    }
    <div class="name">
      <dmc-ng-tooltip
        [width]="'100%'"
        [text]="displayName"
      ></dmc-ng-tooltip>
      @if (otherInformations) {
        <dmc-ng-tooltip
          class="other-informations"
          [width]="'100%'"
          [text]="otherInformations"
        ></dmc-ng-tooltip>
      }
    </div>
  </div>
</div>
