import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs';

import { selectHasRightToAccess } from '../state/dmc-console.selectors';
import { RoutingUtils } from '../utils/routing.utils';

export const accessPageGuard =
  (permission: string): CanActivateFn =>
  () => {
    const store: Store = inject(Store);
    const router = inject(Router);

    return store.pipe(
      select(selectHasRightToAccess(permission)),
      map(
        (hasAccess) =>
          hasAccess || router.createUrlTree([RoutingUtils.pages.error403]),
      ),
    );
  };
