<dmc-ng-base-dialog
  [inline]="true"
  (closeDialog)="data.clickOnCancel()"
>
  <dmc-ng-icon
    dialog-icon
    [color]="iconColorEnum.Primary"
    [size]="iconSizeEnum.LG"
    [theme]="iconThemeEnum.Modern"
    icon="upload-cloud-02"
  ></dmc-ng-icon>
  <h4 dialog-title>Upload Logo</h4>

  <ng-container dialog-content>
    @if (imageState() === 'loading') {
      <div class="spinner-container">
        <mat-spinner></mat-spinner>
      </div>
    }
    <div class="container">
      @if (imageState() === 'failed') {
        <p>We're sorry, but there was an issue loading your image.</p>
      } @else {
        <image-cropper
          [alignImage]="'center'"
          [imageFile]="data.file"
          [maintainAspectRatio]="true"
          [containWithinAspectRatio]="true"
          [resizeToWidth]="IMAGE_SIZE"
          [resizeToHeight]="IMAGE_SIZE"
          [onlyScaleDown]="true"
          [format]="IMAGE_FORMAT"
          [output]="IMAGE_OUTPUT"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()"
          (loadImageFailed)="loadImageFailed()"
          [class.visible]="imageState() === 'load'"
        ></image-cropper>

        <div class="result-container">
          @if (imageState() !== 'loading') {
            <span>Preview</span>
            <dmc-ng-avatar
              [classType]="'circle'"
              [value]=""
              [width]="112"
              [pictureUrl]="croppedImage"
            ></dmc-ng-avatar>
          }
        </div>
      }
    </div>
  </ng-container>

  <div dialog-actions>
    <button
      mat-stroked-button
      color="secondary"
      class="medium cancel"
      (click)="data.clickOnCancel()"
      [autofocus]="false"
    >
      Cancel
    </button>
    <button
      mat-raised-button
      color="primary"
      class="medium save"
      (click)="data.clickOnSave(imageToUpload, IMAGE_FORMAT, croppedImage!)"
      [class.hidden]="imageState() === 'failed'"
      [disabled]="imageState() === 'loading'"
    >
      Save
    </button>
  </div>
</dmc-ng-base-dialog>
