import { Pipe, PipeTransform } from '@angular/core';
import { isNumber } from 'lodash';

@Pipe({
  name: 'zeroValue',
  standalone: true,
})
export class ZeroValuePipe implements PipeTransform {
  transform(value: string | number, percent = false): string {
    if (isNumber(value)) {
      return value !== 0 ? (percent ? `${value}%` : value.toString()) : '-';
    } else {
      return value !== '0' ? (percent ? `${value}%` : value) : '-';
    }
  }
}
