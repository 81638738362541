import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SelectComponent } from '@dmc-ng/ui/form';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

interface selectParams extends ICellRendererParams {
  roles: { value: string; name: string }[];
  control: FormControl;
}

@Component({
  standalone: true,
  imports: [
    SelectComponent,
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  templateUrl: './select-cell.component.html',
  styleUrl: './select-cell.component.scss',
})
export class SelectCellRendererComponent implements ICellRendererAngularComp {
  params!: selectParams;
  control!: FormControl;
  roles!: { value: string; name: string }[];

  agInit(params: selectParams): void {
    this.params = params;
    this.control = params.control;
    this.roles = params.roles;
  }

  refresh(params: selectParams): boolean {
    return true;
  }
}
