import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'dmc-ng-paginator',
  standalone: true,
  imports: [CommonModule, MatPaginatorModule],
  templateUrl: './paginator.component.html',
  styleUrl: './paginator.component.scss',
})
export class PaginatorComponent {
  @Input() total = 0;
  @Input() pageSize = 10;
  @Input() index = 0;
  @Input() hidePageSize = true;
  @Output() pageIndex = new EventEmitter<number>();
  @Output() pageChange = new EventEmitter<PageEvent>();

  previousPageSize = this.pageSize;

  pageChanged(event: PageEvent): void {
    this.pageChange.emit(event);

    if (this.hidePageSize) {
      this.pageIndex.emit(event.pageIndex);
    }
  }
}
