import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { selectOrganizations } from '@dmc-ng/data-access/organizations';
import { LocalStorageService } from '@dmc-ng/local-storage';
import { select, Store } from '@ngrx/store';
import { map, take } from 'rxjs';

import { LocalStorageEnum } from '../models/enum/local-storage.enum';
import { RoutingUtils } from '../utils/routing.utils';

export const redirectOrganizationOverviewGuard: CanActivateFn = () => {
  const store: Store = inject(Store);
  const router = inject(Router);
  const localStorage = inject(LocalStorageService);

  return store.pipe(
    select(selectOrganizations),
    take(1),
    map((organizations) => {
      if (!organizations.length) {
        return router.createUrlTree([RoutingUtils.pages.accountManagement]);
      }

      const firstOrganization = organizations[0];
      const organizationId =
        localStorage.getData(LocalStorageEnum.OrganizationId) ??
        firstOrganization.id;
      const accountId = firstOrganization.accounts?.length
        ? localStorage.getData(LocalStorageEnum.AccountId) ||
          firstOrganization.accounts[0].id
        : null;

      return router.createUrlTree([`/${organizationId}/overview`], {
        queryParams: {
          [RoutingUtils.queryParams.accountId]: accountId,
        },
      });
    }),
  );
};
