import { Injectable } from '@angular/core';
import { APIResponse } from '@dmc-ng/data-access';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';

import { meActions } from './me.actions';
import { MeService } from '../me.service';
import { UserMeModel, UserMePolicyModel } from '../models/user/user-me.model';

@Injectable()
export class MeEffects {
  userPolicies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(meActions.getPolicies),
      switchMap(() =>
        this.dmcConsoleService.loadMe().pipe(
          map(({ data }) =>
            meActions.mePoliciesRetrievedSuccessfully({
              policies: (data as UserMeModel).policies as UserMePolicyModel[],
              superAdmin: (data as UserMeModel).su,
              role: (data as UserMeModel).computedRole.label,
            }),
          ),
          catchError((error) =>
            of(meActions.mePoliciesFailedToBeRetrieved({ error })),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private dmcConsoleService: MeService,
  ) {}
}
