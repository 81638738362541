import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { APIResponse, DeepPartial, UploadLogoModel } from '@dmc-ng/data-access';
import { API_BASE_URL } from '@dmc-ng/data-access/injection-token';
import { Observable } from 'rxjs';

import { SetUserRequestStoreModel } from './models/set-user-request-store.model';
import { ScopedUserModel, UserApiModel } from './models/user-api.model';

@Injectable()
export class UserService {
  private readonly apiBaseUrl = inject(API_BASE_URL);

  constructor(private readonly httpClient: HttpClient) {}

  postUser(
    userToSave: DeepPartial<SetUserRequestStoreModel>,
  ): Observable<APIResponse<ScopedUserModel>> {
    return this.httpClient.post<APIResponse<ScopedUserModel>>(
      `${this.apiBaseUrl}/users/`,
      userToSave,
    );
  }

  getUser(userId: string): Observable<APIResponse<ScopedUserModel>> {
    return this.httpClient.get<APIResponse<ScopedUserModel>>(
      `${this.apiBaseUrl}/users/${userId}`,
    );
  }

  putUser(
    userId: string,
    userToEdit: DeepPartial<ScopedUserModel>,
  ): Observable<APIResponse<ScopedUserModel>> {
    return this.httpClient.put<APIResponse<ScopedUserModel>>(
      `${this.apiBaseUrl}/users/${userId}`,
      userToEdit,
    );
  }

  deleteUser(userId: string, organizationId?: string): Observable<void> {
    let params = new HttpParams();

    if (organizationId) {
      params = params.append('organizationId', organizationId);
    }
    return this.httpClient.delete<void>(`${this.apiBaseUrl}/users/${userId}`, {
      params,
    });
  }

  searchUsers(
    organizationId?: string,
    query?: string,
    limit = 10,
    withPhone = false,
    withSU = true,
  ): Observable<APIResponse<UserApiModel[]>> {
    let params = new HttpParams();

    if (organizationId) {
      params = params.append('organizationId', organizationId);
    }

    if (withPhone) {
      params = params.append('with-phone', withPhone);
    }

    if (query) {
      params = params.append('q', query);
    }

    if (withSU) {
      params = params.append('add-su', withSU);
    }

    params = params.append('limit', limit);

    return this.httpClient.get<APIResponse<UserApiModel[]>>(
      `${this.apiBaseUrl}/users/all`,
      { params },
    );
  }

  postProfileLogo(
    userId: string,
    uploadLogo: UploadLogoModel,
  ): Observable<APIResponse<ScopedUserModel>> {
    return this.httpClient.post<APIResponse<ScopedUserModel>>(
      `${this.apiBaseUrl}/users/${userId}/picture`,
      uploadLogo,
    );
  }
}
