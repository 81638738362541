import { LoadingStateEnum } from '@dmc-ng/data-access';
import { Action, createReducer, on } from '@ngrx/store';

import { resourcesActions } from './resources.actions';
import { ResourceGeocoding, ResourceModel } from '../models/resource.model';

export const RESOURCES_FEATURE_KEY = 'resources';

export interface ResourcesState {
  resources?: ResourceModel;
  loading: LoadingStateEnum;
  error?: unknown;
}

export interface ResourcesPartialState {
  readonly [RESOURCES_FEATURE_KEY]: ResourcesState;
}

export const initialResourcesState: ResourcesState = {
  loading: LoadingStateEnum.Ready,
};

const reducer = createReducer(
  initialResourcesState,
  on(resourcesActions.getResources, resourcesActions.getCities, (state) => ({
    ...state,
    loading: LoadingStateEnum.Loading,
  })),
  on(
    resourcesActions.resourcesRetrievedSuccessfully,
    (state, { resources }) => ({
      ...state,
      resources,
      loading: LoadingStateEnum.Done,
    }),
  ),
  on(resourcesActions.citiesRetrievedSuccessfully, (state, { cities }) => ({
    ...state,
    resources: {
      ...state.resources,
      cities: cities ?? [],
    },
    loading: LoadingStateEnum.Done,
  })),
  on(
    resourcesActions.departmentsFilteredSuccessfully,
    (state, { departments }) => ({
      ...state,
      resources: {
        ...state.resources,
        filteredDepartments: departments ?? [],
      },
      loading: LoadingStateEnum.Done,
    }),
  ),
  on(
    resourcesActions.resourcesFailedToBeRetrieved,
    resourcesActions.citiesFailedToBeRetrieved,
    resourcesActions.departmentsFailedToBeFiltered,
    (state, { error }) => ({
      ...state,
      error,
      loading: LoadingStateEnum.Done,
    }),
  ),
);

export function resourcesReducer(
  state: ResourcesState | undefined,
  action: Action,
): ResourcesState {
  return reducer(state, action);
}
