import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AvatarComponent } from '@dmc-ng/ui/avatar';
import { TooltipComponent } from '@dmc-ng/ui/tooltip';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

interface CreatedByParams extends ICellRendererParams {
  displayName: string;
  emailAddress: string;
  pictureUrl: string;
}

@Component({
  standalone: true,
  imports: [CommonModule, AvatarComponent, TooltipComponent],
  templateUrl: './created-by.component.html',
  styleUrl: './created-by.component.scss',
})
export class CreatedByCellRendererComponent
  implements ICellRendererAngularComp
{
  displayName!: string;
  emailAddress!: string;
  pictureUrl!: string;

  agInit(params: CreatedByParams): void {
    this.displayName = params.displayName;
    this.emailAddress = params.emailAddress;
    this.pictureUrl = params.pictureUrl;
  }

  refresh(params: CreatedByParams): boolean {
    this.displayName = params.displayName;
    this.emailAddress = params.emailAddress;
    this.pictureUrl = params.pictureUrl;
    return true;
  }
}
