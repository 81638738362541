import { CampaignStateEnum } from '@dmc-ng/data-access/campaigns';

import { StateLabelEnum } from './enum/state-label.enum';

export const EquivalenceColor: {
  [key in CampaignStateEnum]?: string;
} = {
  [CampaignStateEnum.Draft]: '#D0D5DD',
  [CampaignStateEnum.Ready]: '#FDB022',
  [CampaignStateEnum.Processing]: '#FDB022',
  [CampaignStateEnum.Success]: '#12B76A',
  [CampaignStateEnum.Error]: '#F04438',
};

export const EquivalenceName: {
  [key in CampaignStateEnum]?: StateLabelEnum;
} = {
  [CampaignStateEnum.Draft]: StateLabelEnum.Draft,
  [CampaignStateEnum.Ready]: StateLabelEnum.InProgress,
  [CampaignStateEnum.ValidationRequested]: StateLabelEnum.PendingApproval,
  [CampaignStateEnum.Processing]: StateLabelEnum.InProgress,
  [CampaignStateEnum.Success]: StateLabelEnum.Finished,
  [CampaignStateEnum.Error]: StateLabelEnum.Error,
};
