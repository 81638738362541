<div class="cell-container d-flex align-items-center h-100">
  <mat-slider
    [max]="maxPotential"
    [min]="0"
  >
    <input
      matSliderThumb
      [(ngModel)]="selectedPotential"
      (change)="onSliderChange()"
    />
  </mat-slider>
</div>
