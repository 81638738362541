import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  CAMPAIGNS_FEATURE_KEY,
  CampaignsSearchState,
  CampaignsState,
  PaginatedCampaignsState,
  campaignsSearchAdapter,
  paginatedCampaignAdapter,
} from './campaigns.reducers';

const campaignsStateFeatureSelector = createFeatureSelector<CampaignsState>(
  CAMPAIGNS_FEATURE_KEY,
);

export const campaignsLoading = createSelector(
  campaignsStateFeatureSelector,
  (state: CampaignsState) => state.loading,
);

export const campaignsError = createSelector(
  campaignsStateFeatureSelector,
  (state: CampaignsState) => state.error,
);

// CAMPAIGNS WITH QUERY
const { selectAll: campaignsWithQueryValues } =
  campaignsSearchAdapter.getSelectors();

const campaignsWithQueryStateSelector = createSelector(
  campaignsStateFeatureSelector,
  (state: CampaignsState) => state.campaignsSearch,
);

export const campaignsWithQuery = createSelector(
  campaignsWithQueryStateSelector,
  campaignsWithQueryValues,
);

export const campaignsWithQueryError = createSelector(
  campaignsWithQueryStateSelector,
  (state: CampaignsSearchState) => state.error,
);

export const campaignsWithQueryLoading = createSelector(
  campaignsWithQueryStateSelector,
  (state: CampaignsSearchState) => state.loading,
);

// PAGINATED CAMPAIGNS
const { selectAll: paginatedCampaignsValues } =
  paginatedCampaignAdapter.getSelectors();

const paginatedCampaignsStateSelector = createSelector(
  campaignsStateFeatureSelector,
  (state: CampaignsState) => state.paginatedCampaigns,
);

export const paginatedCampaigns = createSelector(
  paginatedCampaignsStateSelector,
  paginatedCampaignsValues,
);

export const paginatedCampaignsError = createSelector(
  paginatedCampaignsStateSelector,
  (state: PaginatedCampaignsState) => state.error,
);

export const paginatedCampaignsLoading = createSelector(
  paginatedCampaignsStateSelector,
  (state: PaginatedCampaignsState) => state.loading,
);

export const paginatedCampaignsTotal = createSelector(
  paginatedCampaignsStateSelector,
  (state: PaginatedCampaignsState) => state.total,
);

export const paginatedCampaignsTotalInCurrentOffset = createSelector(
  paginatedCampaignsStateSelector,
  (state: PaginatedCampaignsState) => state.totalInCurrentOffset,
);
