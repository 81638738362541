import {
  Directive,
  Input,
  OnInit,
  ViewContainerRef,
  TemplateRef,
  DestroyRef,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserMePolicyModel } from '@dmc-ng/data-access/me';
import { Store, select } from '@ngrx/store';
import { combineLatest } from 'rxjs';

import {
  selectCurrentOrganizationId,
  selectIsSuperAdmin,
  selectUserPolicies,
} from '../state/dmc-console.selectors';

@Directive({
  selector: '[dmcConsoleAccessToElement]',
  standalone: true,
})
export class AccessToElementDirective implements OnInit {
  private permission?: string;
  private destroyRef = inject(DestroyRef);

  private hasView = false;

  private currentSU = false;
  private currentOrganizationId?: string;
  private currentPolicies: UserMePolicyModel[] = [];

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private store: Store,
  ) {}

  @Input() set dmcConsoleAccessToElement(perm: string) {
    this.permission = perm;
    this.updateView();
  }

  updateView(): void {
    const authorized: boolean =
      this.currentSU ||
      (!!this.permission &&
        !!this.currentOrganizationId &&
        this.currentPolicies
          .filter(
            (policy) => policy.organizationId === this.currentOrganizationId,
          )
          .some((policiy) =>
            policiy.permissions.includes('' + this.permission),
          ));

    if (authorized && !this.hasView) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!authorized && this.hasView) {
      this.viewContainerRef.clear();
      this.hasView = false;
    }
  }

  ngOnInit(): void {
    combineLatest([
      this.store.pipe(select(selectIsSuperAdmin)),
      this.store.pipe(select(selectCurrentOrganizationId)),
      this.store.pipe(select(selectUserPolicies)),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([superAdmin, organizationId, policies]) => {
        this.currentSU = !!superAdmin;
        this.currentOrganizationId = organizationId;
        this.currentPolicies = [...(policies || [])];

        this.updateView();
      });
  }
}
