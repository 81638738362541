import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  USER_FEATURE_KEY,
  UserState,
  usersSearchAdapter,
} from './user.reducers';

const { selectEntities: userSearchEntities, selectAll: usersSearch } =
  usersSearchAdapter.getSelectors();

export const selectUserState =
  createFeatureSelector<UserState>(USER_FEATURE_KEY);
export const selectUserLoading = createSelector(
  selectUserState,
  (state: UserState) => state.loading,
);
export const selectUserDetailsLoading = createSelector(
  selectUserState,
  (state: UserState) => state.loadingOneUser,
);
export const selectCurrentUser = createSelector(
  selectUserState,
  (state: UserState) => state.currentUser,
);
export const selectCurrentUserToken = createSelector(
  selectUserState,
  (state: UserState) => state.currentUser?.token,
);

export const selectUsersSearchState = createSelector(
  selectUserState,
  (state) => state.usersSearch,
);

export const selecUsersSearchEntities = createSelector(
  selectUsersSearchState,
  userSearchEntities,
);

export const selectUsersSearch = createSelector(
  selectUsersSearchState,
  usersSearch,
);

export const selectUsersSearchLoading = createSelector(
  selectUsersSearchState,
  (state) => state.loading,
);

export const selectUsersSearchError = createSelector(
  selectUsersSearchState,
  (state) => state.error,
);
