import { isEqual } from 'lodash';

export type ComparableValue =
  | string
  | number
  | boolean
  | object
  | null
  | undefined;

export function isFormModified<T>(initialValues: T, currentValues: T): boolean {
  return !isEqual(initialValues, currentValues);
}
