import { APIResponse, DeepPartial, UploadLogoModel } from '@dmc-ng/data-access';
import { props, createActionGroup, emptyProps } from '@ngrx/store';

import { OrganizationModel } from '../models/organizations.model';

export const organizationsActions = createActionGroup({
  source: 'Organizations',
  events: {
    'Get Organizations': props<{ withAccounts?: boolean }>(),
    'Organizations retrieved successfully': props<{
      organizations: OrganizationModel[];
    }>(),
    'Organizations failed to be retrieved': props<{ error: unknown }>(),
    'Clear organizations': emptyProps(),

    'Get paginated Organizations': props<{
      withAccounts: boolean;
      limit?: number;
      offset?: number;
    }>(),
    'Organizations paginated retrieved successfully': props<{
      organizations: OrganizationModel[];
      total: number;
      totalInCurrentOffset: number;
    }>(),
    'Organizations paginated failed to be retrieved': props<{
      error: unknown;
    }>(),

    'Create Organization': props<{
      organization: DeepPartial<OrganizationModel>;
      logo?: UploadLogoModel;
    }>(),
    'Organization created successfully': props<{
      organization: APIResponse<OrganizationModel>;
      logo?: UploadLogoModel;
    }>(),
    'Organization failed to be created': props<{ error: unknown }>(),

    'Update Organization': props<{
      organizationId: string;
      organization: DeepPartial<OrganizationModel>;
      logo?: UploadLogoModel;
    }>(),
    'Organization updated successfully': props<{
      organization: APIResponse<OrganizationModel>;
      logo?: UploadLogoModel;
    }>(),
    'Organization failed to be updated': props<{ error: unknown }>(),

    'Delete Organization': props<{ organizationId: string }>(),
    'Organization delete successfully': props<{ organizationId: string }>(),
    'Organization failed to be deleted': props<{ error: unknown }>(),

    'Get one Organization': props<{ organizationId: string }>(),
    'Organization retrieved successfully': props<{
      organization: APIResponse<OrganizationModel>;
    }>(),
    'Organization failed to be retrieved': props<{ error: unknown }>(),

    'Add logo for Organization': props<{
      organizationId: string;
      uploadLogo: UploadLogoModel;
    }>(),
    'Organization logo added successfully': props<{
      organization: APIResponse<OrganizationModel>;
    }>(),
    'Organization logo failed to be added': props<{ error: unknown }>(),
  },
});
