import { Injectable } from '@angular/core';
import { UserMePolicyModel } from '@dmc-ng/data-access/me';
import { Store, select } from '@ngrx/store';

import {
  selectIsSuperAdmin,
  selectCurrentOrganizationId,
  selectUserPolicies,
} from '../state/dmc-console.selectors';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private currentSU = false;
  private currentOrganizationId?: string;
  private currentPolicies: UserMePolicyModel[] = [];

  constructor(private store: Store) {
    this.store.pipe(select(selectIsSuperAdmin)).subscribe((superAdmin) => {
      this.currentSU = !!superAdmin;
    });

    this.store
      .pipe(select(selectCurrentOrganizationId))
      .subscribe((organizationId) => {
        this.currentOrganizationId = organizationId;
      });

    this.store.pipe(select(selectUserPolicies)).subscribe((policies) => {
      this.currentPolicies = policies || [];
    });
  }

  hasPermission(permission?: string): boolean {
    return (
      this.currentSU ||
      (!!permission &&
        !!this.currentOrganizationId &&
        this.currentPolicies.some(
          (policy) =>
            policy.organizationId === this.currentOrganizationId &&
            policy.permissions.includes(permission),
        ))
    );
  }

  isSuperAdmin(): boolean {
    return this.currentSU;
  }
}
