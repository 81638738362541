<form (keydown)="onKeyDown($event)">
  <mat-form-field>
    <input
      matInput
      [type]="inputType"
      [formControl]="control"
    />
    @if (control.value) {
      <mat-icon
        class="close-icon gray-stroke-400"
        [ngClass]="{ 'on-error': control.invalid }"
        matSuffix
        aria-label="Clear"
        svgIcon="x-close"
        (click)="control.reset()"
      ></mat-icon>
    }
  </mat-form-field>
</form>
