import { LoadingStateEnum } from '@dmc-ng/data-access';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { isArray } from 'lodash';

import { userActions } from './user.actions';
import { UserStoreModel } from '../models/user-store.model';

export const USER_FEATURE_KEY = 'user';

export const usersSearchAdapter: EntityAdapter<any> =
  createEntityAdapter<any>();

export interface UsersSearchState extends EntityState<any> {
  error?: unknown;
  loading: LoadingStateEnum;
}

export interface UserState {
  usersSearch: UsersSearchState;
  currentUser?: UserStoreModel;
  loading: LoadingStateEnum;
  loadingOneUser: LoadingStateEnum;
  error?: unknown;
}

export interface UserPartialState {
  readonly [USER_FEATURE_KEY]: UserState;
}

export const initialUserState: UserState = {
  loading: LoadingStateEnum.Ready,
  loadingOneUser: LoadingStateEnum.Ready,
  usersSearch: usersSearchAdapter.getInitialState({
    loading: LoadingStateEnum.Ready,
  }),
};

const reducer = createReducer(
  initialUserState,
  on(userActions.setCurrentUser, (state, { user }) => ({
    ...state,
    currentUser: user,
  })),
  on(userActions.resetCurrentUser, (state) => ({
    ...state,
    currentUser: undefined,
  })),
  on(
    userActions.createUser,
    userActions.getUserDetails,
    userActions.updateUser,
    userActions.deleteUser,
    (state) => ({ ...state, loading: LoadingStateEnum.Loading }),
  ),
  on(
    userActions.userCreatedSuccessfully,
    userActions.userDetailsRetrievedSuccessfully,
    userActions.userUpdatedSuccessfully,
    userActions.userDeleteSuccessfully,
    (state) => ({
      ...state,
      loading: LoadingStateEnum.Done,
    }),
  ),
  on(
    userActions.userFailedToBeCreated,
    userActions.userDetailsFailedToBeRetrieved,
    userActions.userDetailsFailedToBeRetrieved,
    userActions.userFailedToBeDeleted,
    (state, { error }) => ({
      ...state,
      error,
      loading: LoadingStateEnum.Done,
    }),
  ),
  on(userActions.getUsersWithSearch, (state) => ({
    ...state,
    usersSearch: {
      ...state.usersSearch,
      loading: LoadingStateEnum.Loading,
    },
  })),
  on(userActions.usersSearchedSuccessfully, (state, { users }) => ({
    ...state,
    usersSearch: {
      ...usersSearchAdapter.setAll(
        isArray(users) ? users : [],
        state.usersSearch,
      ),
      loading: LoadingStateEnum.Done,
    },
  })),
  on(userActions.usersFailedToBeSearched, (state, { error }) => ({
    ...state,
    usersSearch: {
      ...state.usersSearch,
      loading: LoadingStateEnum.Done,
      error,
    },
  })),
  on(userActions.getUserDetails, (state) => ({
    ...state,
    loadingOneUser: LoadingStateEnum.Loading,
  })),
  on(userActions.userDetailsRetrievedSuccessfully, (state) => ({
    ...state,
    loadingOneUser: LoadingStateEnum.Done,
  })),
  on(userActions.userDetailsFailedToBeRetrieved, (state) => ({
    ...state,
    loadingOneUser: LoadingStateEnum.Loading,
  })),
);

export function userReducer(
  state: UserState | undefined,
  action: Action,
): UserState {
  return reducer(state, action);
}
