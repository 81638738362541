import { inject } from '@angular/core';
import { UrlTree } from '@angular/router';
import { dialogActions } from '@dmc-ng/dialog';
import { DEFAULT_DIALOG_CONFIG } from '@dmc-ng/utils';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { LoseCurrentWorkDialogComponent } from '../components/dialogs/lose-current-work/lose-current-work-dialog.component';
import {
  selectAllowNavigation,
  selectPreventNavigation,
} from '../state/dmc-console.selectors';

export function canExitGuard():
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree {
  const store = inject(Store);
  return store.select(selectPreventNavigation).pipe(
    switchMap((preventNavigate) => {
      if (!preventNavigate) {
        return of(true);
      } else {
        store.dispatch(
          dialogActions.openDialog({
            customComponent: LoseCurrentWorkDialogComponent,
            config: DEFAULT_DIALOG_CONFIG,
          }),
        );
        return store.select(selectAllowNavigation).pipe(
          filter((isAllowToNavigate) => !isNil(isAllowToNavigate)),
          map((isAllowToNavigate) => isAllowToNavigate as boolean),
        );
      }
    }),
  );
}
