import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { dialogActions } from './dialog.actions';

@Injectable()
export class DialogEffects {
  openDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(dialogActions.openDialog),
        tap(({ customComponent, config, data }) => {
          this.matDialog.open(customComponent, { ...config, data });
        })
      ),
    { dispatch: false }
  );

  closeDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(dialogActions.closeDialog),
        tap(() => this.matDialog.closeAll())
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private matDialog: MatDialog) {}
}
