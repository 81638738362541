import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import {
  IconColorEnum,
  IconComponent,
  IconSizeEnum,
  IconThemeEnum,
} from '@dmc-ng/ui/icon';

import { DeleteDialogModel } from '../../models/delete-dialog.model';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

@Component({
  selector: 'dmc-ng-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    IconComponent,
    BaseDialogComponent,
  ],
  standalone: true,
})
export class DeleteDialogComponent {
  readonly iconSizeEnum: typeof IconSizeEnum = IconSizeEnum;
  readonly iconThemeEnum: typeof IconThemeEnum = IconThemeEnum;
  readonly iconColorEnum: typeof IconColorEnum = IconColorEnum;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DeleteDialogModel) {}
}
