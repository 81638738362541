import { ColDef, GridOptions } from 'ag-grid-community';

export const defaultGridOptions: GridOptions = {
  cellSelection: false,
  animateRows: true,
  suppressContextMenu: true,
  overlayLoadingTemplate: `<span class="ag-overlay-loading-center">Loading...</span>`,
  overlayNoRowsTemplate: `<span class="ag-overlay-no-rows-center">No Data Available</span>`,
};

export const defaultColDef: ColDef = {
  flex: 1,
  resizable: true,
  suppressHeaderMenuButton: true,
  wrapText: true,
};
