import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { dialogActions } from '@dmc-ng/dialog';
import { overlayActions } from '@dmc-ng/overlay';
import { BaseDialogComponent } from '@dmc-ng/ui/dialogs';
import {
  IconColorEnum,
  IconComponent,
  IconSizeEnum,
  IconThemeEnum,
} from '@dmc-ng/ui/icon';
import { Store } from '@ngrx/store';

import { dmcConsoleActions } from '../../../state/dmc-console.actions';

@Component({
  selector: 'dmc-console-lose-current-work',
  templateUrl: './lose-current-work-dialog.component.html',
  styleUrls: ['./lose-current-work-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    IconComponent,
    BaseDialogComponent,
  ],
  standalone: true,
})
export class LoseCurrentWorkDialogComponent {
  readonly iconSizeEnum: typeof IconSizeEnum = IconSizeEnum;
  readonly iconThemeEnum: typeof IconThemeEnum = IconThemeEnum;
  readonly iconColorEnum: typeof IconColorEnum = IconColorEnum;

  constructor(private store: Store) {}

  cancel(): void {
    this.store.dispatch(dialogActions.closeDialog());
    this.store.dispatch(
      dmcConsoleActions.isAllowedToNavigate({ allowNavigate: false }),
    );
    this.store.dispatch(
      dmcConsoleActions.preventNavigation({ preventNavigate: true }),
    );
    this.store.dispatch(
      dmcConsoleActions.isAllowedToNavigate({ allowNavigate: undefined }),
    );
  }

  leave(): void {
    this.store.dispatch(dialogActions.closeDialog());
    this.store.dispatch(overlayActions.closeOverlay());
    this.store.dispatch(
      dmcConsoleActions.isAllowedToNavigate({ allowNavigate: true }),
    );
    this.store.dispatch(
      dmcConsoleActions.preventNavigation({ preventNavigate: false }),
    );
    this.store.dispatch(
      dmcConsoleActions.isAllowedToNavigate({ allowNavigate: undefined }),
    );
  }
}
