import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

interface StatusParams extends ICellRendererParams {
  state: string;
  color: string;
}

@Component({
  standalone: true,
  imports: [CommonModule, MatChipsModule, MatIconModule],
  templateUrl: './status.component.html',
  styleUrl: './status.component.scss',
})
export class StatusCellRendererComponent implements ICellRendererAngularComp {
  state!: string;
  color!: string;

  agInit(params: StatusParams): void {
    this.state = params.state;
    this.color = params.color;
  }

  refresh(params: StatusParams): boolean {
    this.state = params.state;
    this.color = params.color;
    return true;
  }
}
