import { UserModel } from '@dmc-ng/data-access/me';
import { UserStoreModel } from '@dmc-ng/data-access/user';

export class UserUtils {
  static convertUser(user: UserStoreModel): UserModel {
    return {
      email: user.email,
      name: user.name,
      pictureUrl: user.pictureUrl,
      id: user.id,
    };
  }
}
