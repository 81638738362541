import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BadgeComponent } from '@dmc-ng/ui/badge';
import { FirstLetterPipe } from '@dmc-ng/ui/pipes';

@Component({
  selector: 'dmc-ng-avatar',
  standalone: true,
  imports: [CommonModule, FirstLetterPipe],
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss',
})
export class AvatarComponent {
  @Input() classType: 'circle' | 'square' = 'square';
  @Input() border: 'white' | 'white-large' | 'primary' | '' = '';
  @Input() value?: string | null;
  @Input() width = 40;
  @Input() pictureUrl?: string | null;
  @Input() shadow = false;
}
