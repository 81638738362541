import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, RowNode } from 'ag-grid-enterprise';

interface SliderParams extends ICellRendererParams {
  getMaxPotential: (params: SliderParams) => number;
  setValue: (value: number) => void;
}

@Component({
  standalone: true,
  imports: [MatSliderModule, CommonModule, FormsModule],
  templateUrl: './slider-cell.component.html',
  styleUrl: './slider-cell.component.scss',
})
export class SliderCellRendererComponent implements ICellRendererAngularComp {
  selectedPotential = 0;
  maxPotential = 0;
  params!: SliderParams;

  agInit(params: SliderParams): void {
    this.params = params;
    this.selectedPotential = params.value.selectedPotential;
    this.maxPotential = params.value.maxPotentialWithGlobalLimit;
  }

  refresh(params: SliderParams): boolean {
    this.params = params;
    this.selectedPotential = params.value.selectedPotential;
    this.maxPotential = params.value.maxPotentialWithGlobalLimit;
    return true;
  }

  onSliderChange(): void {
    this.params.setValue(this.selectedPotential);
    this.params.api.refreshCells({
      force: true,
      rowNodes: [this.params.node as RowNode],
      columns: ['selectedPotentialValue'],
    });
  }
}
