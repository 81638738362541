@if (pictureUrl) {
  <img
    alt="avatar"
    [ngClass]="[
      classType,
      border ? 'border-' + border : '',
      'default-background',
      shadow ? 'shadow' : ''
    ]"
    [className]="classType"
    referrerpolicy="no-referrer"
    [src]="pictureUrl"
    [style.width.px]="width"
    [style.height.px]="width"
  />
} @else {
  <div
    [ngClass]="[
      classType,
      shadow ? 'shadow' : '',
      border ? 'border-' + border : '',
      'default-background'
    ]"
    [style.width.px]="width"
    [style.height.px]="width"
  >
    <div class="value">
      {{ value | firstLetter | uppercase }}
    </div>
  </div>
}
