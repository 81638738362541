import { Type } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const dialogActions = createActionGroup({
  source: 'Dialog',
  events: {
    'Open Dialog': props<{ customComponent: Type<any>, config?: MatDialogConfig, data?: any }>(),
    'Close Dialog': emptyProps(),
  },
});
