import { Overlay, OverlayConfig } from '@angular/cdk/overlay';

import { CustomOverlayConfig } from '../models/overlay.model';

export abstract class OverlayUtils {
  static createOverlayConfiguration(configuration: CustomOverlayConfig, overlay: Overlay): OverlayConfig {
    return {
      ...configuration,
      positionStrategy: configuration.positionStrategyCreator(overlay)
    };
  }
}
