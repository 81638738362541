import { Injectable } from '@angular/core';
import { APIResponse } from '@dmc-ng/data-access';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';

import { resourcesActions } from './resources.actions';
import { selectDepartements } from './resources.selectors';
import { ResourceGeocoding, ResourceModel } from '../models/resource.model';
import { ResourcesService } from '../resources.service';

@Injectable()
export class ResourcesEffects {
  allResources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resourcesActions.getResources),
      switchMap(() =>
        this.resourcesService.getResources().pipe(
          map((resourcesResult: APIResponse<ResourceModel>) =>
            resourcesActions.resourcesRetrievedSuccessfully({
              resources: resourcesResult.data as ResourceModel,
            }),
          ),
          catchError((error) =>
            of(resourcesActions.resourcesFailedToBeRetrieved({ error })),
          ),
        ),
      ),
    ),
  );

  cities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resourcesActions.getCities),
      switchMap(({ query }) =>
        this.resourcesService.getResources(['cities'], query, 10).pipe(
          map((citiesResult: APIResponse<ResourceModel>) =>
            resourcesActions.citiesRetrievedSuccessfully({
              cities: citiesResult.data?.cities as ResourceGeocoding[],
            }),
          ),
          catchError((error) =>
            of(resourcesActions.citiesFailedToBeRetrieved({ error })),
          ),
        ),
      ),
    ),
  );

  departments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resourcesActions.getDepartments),
      withLatestFrom(this.store.select(selectDepartements)),
      map(([action, departments]) => {
        const { query, limit } = action;
        const lowerCaseQuery = query.toLowerCase();
        const filteredDepartments = departments
          .filter((department: ResourceGeocoding) =>
            department.label.toLowerCase().includes(lowerCaseQuery),
          )
          .slice(0, limit);

        return resourcesActions.departmentsFilteredSuccessfully({
          departments: filteredDepartments,
        });
      }),
      catchError((error) =>
        of(resourcesActions.departmentsFailedToBeFiltered({ error })),
      ),
    ),
  );

  getResourcesFromType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resourcesActions.getResourcesFromType),
      switchMap(({ resourceType, request }) =>
        this.resourcesService.postResouces(resourceType, request).pipe(
          map((resourcesResult: APIResponse<ResourceModel>) =>
            resourcesActions.resourcesFromTypeRetrievedSuccessfully({
              resources: resourcesResult.data as ResourceModel,
            }),
          ),
          catchError((error) =>
            of(
              resourcesActions.resourcesFromTypeFailedToBeRetrieved({ error }),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private resourcesService: ResourcesService,
    private store: Store,
  ) {}
}
