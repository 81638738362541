import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

interface CheckboxParams extends ICellRendererParams {
  onClick: (checked: boolean, rowData: any) => void;
  isChecked: () => boolean;
}

@Component({
  standalone: true,
  imports: [CommonModule, MatCheckboxModule],
  templateUrl: './checkbox-cell.component.html',
  styleUrl: './checkbox-cell.component.scss',
})
export class CheckboxCellRendererComponent implements ICellRendererAngularComp {
  params!: CheckboxParams;
  isSelected = false;

  agInit(params: CheckboxParams): void {
    this.params = params;
  }

  refresh(params: CheckboxParams): boolean {
    return true;
  }

  onClick(event: MatCheckboxChange): void {
    this.isSelected = event.checked;
    this.params.onClick(this.isSelected, this.params.data);
  }
}
