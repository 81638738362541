<div class="cell-container d-flex align-items-center h-100">
  @if (canCancel) {
    <button
      mat-icon-button
      class="icon-small"
      (click)="onClick(actionsCellEnum.Cancel, $event)"
    >
      <mat-icon
        class="icon gray-stroke-700"
        svgIcon="calendar-minus"
      ></mat-icon>
    </button>
  }
  @if (canSee) {
    <button
      mat-icon-button
      class="icon-small"
      (click)="onClick(actionsCellEnum.See, $event)"
    >
      <mat-icon
        class="icon gray-stroke-700"
        svgIcon="eye"
      ></mat-icon>
    </button>
  }
  @if (canEdit) {
    <button
      mat-icon-button
      class="icon-small"
      (click)="onClick(actionsCellEnum.Edit, $event)"
    >
      <mat-icon
        class="icon gray-stroke-700"
        svgIcon="edit-02"
      ></mat-icon>
    </button>
  }
  @if (canDelete) {
    <button
      mat-icon-button
      class="icon-small"
      (click)="onClick(actionsCellEnum.Delete, $event)"
    >
      <mat-icon
        class="icon gray-stroke-700"
        svgIcon="trash-04"
      ></mat-icon>
    </button>
  }
</div>
