import { saveAs } from 'file-saver';
import { every, isArray } from 'lodash';
import { parse, unparse } from 'papaparse';
import { Observable } from 'rxjs';

export class PapaParseUtils {
  static parseCsv(file: File): Observable<{
    status: number;
    error?: Error;
    result?: any[];
  }> {
    let csvData: any[] = [];
    let lastPercent = 0;
    return new Observable((observable) => {
      parse(file, {
        worker: true,
        skipEmptyLines: true,
        header: true,
        chunkSize: file.size / 100,
        chunk(results) {
          let fields: string[] = [];
          let data: { [key: string]: string }[] = [];
          let needTransform = false;
          if (
            results.data.length &&
            results.meta.fields &&
            results.meta.fields.length
          ) {
            needTransform = /[\r\n]+/g.test(
              results.meta.fields[results.meta.fields.length - 1],
            );

            fields = results.meta.fields.map((field: string) =>
              field.replace(/[\r\n]+/g, ''),
            );

            data = (results.data as { [key: string]: string }[]).map((d) => {
              const cleanedObject: { [key: string]: string } = {};

              if (results.meta.fields) {
                fields.forEach((cleanField: string, index: number) => {
                  const originalField = (results.meta.fields as string[])[
                    index
                  ];
                  const value = d[originalField].replace(/[\r\n]+/g, '');
                  cleanedObject[cleanField] = value;
                });
              }

              return cleanedObject;
            });
          }
          const flattenedData =
            needTransform && data ? data.flat() : results.data.flat();
          csvData = [...csvData, ...flattenedData];
          const percentDone = Math.ceil(
            (results.meta.cursor / file.size) * 100,
          );
          if (percentDone > lastPercent) {
            observable.next({
              status: percentDone,
            });
            lastPercent = percentDone;
          }
        },
        complete() {
          observable.next({
            status: 100,
            result: csvData,
          });
          observable.complete();
        },
        error(error) {
          observable.next({
            status: 100,
            error,
          });
          observable.complete();
        },
      });
    });
  }

  static exportToCsv(data: unknown[], fileName: string): void {
    if (!isArray(data) || !every(data, Object)) {
      throw new Error('Invalid data: data must be an array of objects.');
    }

    const csv = unparse(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `${fileName}.csv`, { autoBom: true });
  }

  static async estimateLineSize(file: File): Promise<number> {
    const fileSize = file.size;
    const sampleSize = Math.max(fileSize * 0.01, 50000);

    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event): void => {
        if (!event.target?.result) {
          resolve(100);
          return;
        }

        const text = event.target.result as string;

        const sample = parse(text, {
          preview: 100,
          skipEmptyLines: true,
          dynamicTyping: false,
          delimiter: '',
        });

        if (!sample.data.length) {
          resolve(100);
          return;
        }

        const avgLineSize =
          (sample.data as string[][]).reduce(
            (acc, row) => acc + JSON.stringify(row).length,
            0,
          ) / sample.data.length;

        resolve(avgLineSize);
      };

      reader.readAsText(file.slice(0, sampleSize));
    });
  }
}
