<div class="cell-container">
  <span>{{ cellValue }}</span>
  <mat-chip-set aria-label="edit cell">
    <mat-chip
      [removable]="false"
      matTooltip="Click on the cell to edit"
      [matTooltipPosition]="tooltipPositionEnum.Below"
      [matTooltipClass]="[tooltipPositionEnum.Below, tooltipColorEnum.Black]"
    >
      <span class="chip-label">Open</span></mat-chip
    >
  </mat-chip-set>
</div>
