import { Injectable } from '@angular/core';
import {
  APIResponse,
  PaginatedResponse,
  UploadLogoModel,
} from '@dmc-ng/data-access';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, of, switchMap } from 'rxjs';

import { organizationsActions } from './organizations.actions';
import { OrganizationModel } from '../models/organizations.model';
import { OrganizationsService } from '../organizations.service';
import { OrganizationsUtils } from '../utils/organizations.utils';

@Injectable()
export class OrganizationsEffects {
  organizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(organizationsActions.getOrganizations),
      switchMap(({ withAccounts }) =>
        this.organizationsService.getOrganizations(withAccounts).pipe(
          map((organizationsResult: APIResponse<OrganizationModel[]>) =>
            organizationsActions.organizationsRetrievedSuccessfully({
              organizations: OrganizationsUtils.convertOrganizations(
                organizationsResult.data as OrganizationModel[],
              ),
            }),
          ),
          catchError((error) =>
            of(
              organizationsActions.organizationsFailedToBeRetrieved({ error }),
            ),
          ),
        ),
      ),
    ),
  );

  paginatedOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(organizationsActions.getPaginatedOrganizations),
      switchMap(({ withAccounts, limit, offset }) =>
        this.organizationsService
          .getPaginatedOrganizations(withAccounts, offset, limit)
          .pipe(
            map(({ data }) =>
              organizationsActions.organizationsPaginatedRetrievedSuccessfully({
                organizations: (data as PaginatedResponse<OrganizationModel>)
                  .items,
                total: (data as PaginatedResponse<OrganizationModel>).total,
                totalInCurrentOffset: (
                  data as PaginatedResponse<OrganizationModel>
                ).items.length,
              }),
            ),
            catchError((error) =>
              of(
                organizationsActions.organizationsPaginatedFailedToBeRetrieved({
                  error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  createOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(organizationsActions.createOrganization),
      switchMap(({ organization, logo }) =>
        this.organizationsService.postOrganization(organization).pipe(
          map((createdOrganization: APIResponse<OrganizationModel>) =>
            organizationsActions.organizationCreatedSuccessfully({
              organization: createdOrganization,
              logo,
            }),
          ),
          catchError((error) =>
            of(organizationsActions.organizationFailedToBeCreated({ error })),
          ),
        ),
      ),
    ),
  );

  updateOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(organizationsActions.updateOrganization),
      switchMap(({ organizationId, organization, logo }) =>
        this.organizationsService
          .putOrganization(organizationId, organization)
          .pipe(
            map((updatedOrganization: APIResponse<OrganizationModel>) =>
              organizationsActions.organizationUpdatedSuccessfully({
                organization: updatedOrganization,
                logo,
              }),
            ),
            catchError((error) =>
              of(organizationsActions.organizationFailedToBeUpdated({ error })),
            ),
          ),
      ),
    ),
  );

  deleteOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(organizationsActions.deleteOrganization),
      switchMap(({ organizationId }) =>
        this.organizationsService.deleteOrganization(organizationId).pipe(
          map(() =>
            organizationsActions.organizationDeleteSuccessfully({
              organizationId,
            }),
          ),
          catchError((error) =>
            of(organizationsActions.organizationFailedToBeDeleted({ error })),
          ),
        ),
      ),
    ),
  );

  organization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(organizationsActions.getOneOrganization),
      switchMap((action) =>
        this.organizationsService.getOrganization(action.organizationId).pipe(
          map((organizationResult: APIResponse<OrganizationModel>) =>
            organizationsActions.organizationRetrievedSuccessfully({
              organization: organizationResult,
            }),
          ),
          catchError((error) =>
            of(organizationsActions.organizationFailedToBeRetrieved({ error })),
          ),
        ),
      ),
    ),
  );

  saveLogoToOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(organizationsActions.addLogoForOrganization),
      switchMap(({ organizationId, uploadLogo }) =>
        this.organizationsService
          .postOrganizationLogo(organizationId, uploadLogo)
          .pipe(
            map((updatedOrganization: APIResponse<OrganizationModel>) =>
              organizationsActions.organizationLogoAddedSuccessfully({
                organization: updatedOrganization,
              }),
            ),
            catchError((error) =>
              of(
                organizationsActions.organizationLogoFailedToBeAdded({ error }),
              ),
            ),
          ),
      ),
    ),
  );

  addLogoForOrganizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        organizationsActions.organizationCreatedSuccessfully,
        organizationsActions.organizationUpdatedSuccessfully,
      ),
      filter(({ logo }) => !!logo),
      map(({ organization, logo }) =>
        organizationsActions.addLogoForOrganization({
          organizationId: organization.data?.id as string,
          uploadLogo: logo as UploadLogoModel,
        }),
      ),
    ),
  );
  constructor(
    private actions$: Actions,
    private organizationsService: OrganizationsService,
  ) {}
}
