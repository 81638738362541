import { props, createActionGroup, emptyProps } from '@ngrx/store';

import {
  PostResourcesRequest,
  ResourceGeocoding,
  ResourceModel,
} from '../models/resource.model';

export const resourcesActions = createActionGroup({
  source: 'Resources',
  events: {
    'Get Resources': emptyProps(),
    'Resources retrieved successfully': props<{ resources: ResourceModel }>(),
    'Resources failed to be retrieved': props<{ error: unknown }>(),

    'Get Resources from type': props<{
      resourceType: string;
      request: PostResourcesRequest;
    }>(),
    'Resources from type retrieved successfully': props<{
      resources: ResourceModel;
    }>(),
    'Resources from type failed to be retrieved': props<{ error: unknown }>(),

    'Get Cities': props<{ query: string }>(),
    'Cities retrieved successfully': props<{ cities: ResourceGeocoding[] }>(),
    'Cities failed to be retrieved': props<{ error: unknown }>(),

    'Get Departments': props<{ query: string; limit: number }>(),
    'Departments filtered successfully': props<{
      departments: ResourceGeocoding[];
    }>(),
    'Departments failed to be filtered': props<{ error: unknown }>(),
  },
});
