<ag-grid-angular
  dmcConsoleAgGridAction
  class="ag-theme-quartz ag-theme-dmc without-padd-first-row"
  style="width: 100%; height: 100%"
  [defaultColDef]="defaultColDef"
  [gridOptions]="gridOptions"
  [columnDefs]="columnDefs"
  [rowData]="campaignMetricGridData"
  [components]="frameworkComponents"
  [loading]="loading"
  [clickPermission]="permission"
  (rowClicked)="clickOnRow($event)"
  (sortChanged)="onSortChanged($event)"
></ag-grid-angular>
<div class="custom-paginator">
  <dmc-ng-paginator
    [total]="paginatorTotal"
    [index]="paginatorIndex"
    (pageIndex)="paginatorChange.emit($event)"
  ></dmc-ng-paginator>
</div>
