import { Injectable } from '@angular/core';
import { APIResponse, PaginatedResponse } from '@dmc-ng/data-access';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';

import { campaignsActions } from './campaigns.actions';
import { CampaignsService } from '../campaigns.service';
import { CampaignModel } from '../models/campaign.model';
import { CampaignsMetricsOverviewModel } from '../models/campaigns-metrics-overview.model';
import { ChangeStateEnum } from '../models/enum/change-state.enum';

@Injectable()
export class CampaignsEffects {
  campaignsWithQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(campaignsActions.getCampaignsWithQuery),
      switchMap(({ organizationId, accountId, query, limit, status }) =>
        this.campaignsService
          .getCampaigns(organizationId, accountId, query, limit, status)
          .pipe(
            map((result: APIResponse<CampaignModel[]>) =>
              campaignsActions.campaignsWithQueryRetrievedSuccessfully({
                campaigns: result.data ?? [],
              }),
            ),
            catchError((error) =>
              of(
                campaignsActions.campaignsWithQueryFailedToBeRetrieved({
                  error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  paginatedCampaigns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(campaignsActions.getPaginatedCampaigns),
      switchMap(({ organizationId, accountId, offset, limit, status, sort }) =>
        this.campaignsService
          .getPaginatedCampaigns(
            organizationId,
            accountId,
            offset,
            limit,
            status,
            sort,
          )
          .pipe(
            map((result: APIResponse<PaginatedResponse<CampaignModel>>) =>
              campaignsActions.campaignsPaginatedRetrievedSuccessfully({
                campaigns:
                  (result.data as PaginatedResponse<CampaignModel>).items ?? [],
                total: (result.data as PaginatedResponse<CampaignModel>).total,
                totalInCurrentOffset: (
                  result.data as PaginatedResponse<CampaignModel>
                ).items.length,
              }),
            ),
            catchError((error) =>
              of(
                campaignsActions.campaignsPaginatedFailedToBeRetrieved({
                  error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  campaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(campaignsActions.getOneCampaign),
      switchMap(({ organizationId, accountId, campaignId }) =>
        this.campaignsService
          .getOneCampaign(organizationId, accountId, campaignId)
          .pipe(
            map((campaignResult: APIResponse<CampaignModel>) =>
              campaignsActions.campaignRetrievedSuccessfully({
                campaign: campaignResult,
              }),
            ),
            catchError((error) =>
              of(campaignsActions.campaignFailedToBeRetrieved({ error })),
            ),
          ),
      ),
    ),
  );

  createCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(campaignsActions.createCampaign),
      switchMap(({ organizationId, accountId, campaign, typeAction }) =>
        this.campaignsService
          .createCampaign(organizationId, accountId, campaign)
          .pipe(
            map((createdCampaign: APIResponse<CampaignModel>) =>
              campaignsActions.campaignCreatedSuccessfully({
                campaign: createdCampaign,
                typeAction,
              }),
            ),
            catchError((error) =>
              of(campaignsActions.campaignFailedToBeCreated({ error })),
            ),
          ),
      ),
    ),
  );

  updateCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(campaignsActions.updateCampaign),
      switchMap(
        ({ organizationId, accountId, campaignId, campaign, typeAction }) =>
          this.campaignsService
            .updateCampaign(organizationId, accountId, campaignId, campaign)
            .pipe(
              map((updatedCampaign: APIResponse<CampaignModel>) =>
                campaignsActions.campaignUpdatedSuccessfully({
                  campaign: updatedCampaign,
                  typeAction,
                }),
              ),
              catchError((error) =>
                of(campaignsActions.campaignFailedToBeUpdated({ error })),
              ),
            ),
      ),
    ),
  );

  deleteCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(campaignsActions.deleteCampaign),
      switchMap(({ organizationId, accountId, campaignId, offset, status }) =>
        this.campaignsService
          .deleteCampaign(organizationId, accountId, campaignId)
          .pipe(
            map(() =>
              campaignsActions.campaignDeleteSuccessfully({
                campaignId,
                offset,
                status,
              }),
            ),
            catchError((error) =>
              of(campaignsActions.campaignFailedToBeDeleted({ error })),
            ),
          ),
      ),
    ),
  );

  launchCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(campaignsActions.launchCampaign),
      switchMap(
        ({ organizationId, accountId, campaignId, afterCreateOrUpdate }) =>
          this.campaignsService
            .changeCampaignState(
              organizationId,
              accountId,
              campaignId,
              ChangeStateEnum.Schedule,
            )
            .pipe(
              map(() =>
                campaignsActions.campaignLaunchedSuccessfully({
                  campaignId,
                  afterCreateOrUpdate,
                }),
              ),
              catchError((error) =>
                of(campaignsActions.campaignFailedToBeLaunched({ error })),
              ),
            ),
      ),
    ),
  );

  validateCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(campaignsActions.validateCampaign),
      switchMap(
        ({ organizationId, accountId, campaignId, afterCreateOrUpdate }) =>
          this.campaignsService
            .changeCampaignState(
              organizationId,
              accountId,
              campaignId,
              ChangeStateEnum.RequestValidation,
            )
            .pipe(
              map(() =>
                campaignsActions.campaignValidatedSuccessfully({
                  campaignId,
                  afterCreateOrUpdate,
                }),
              ),
              catchError((error) =>
                of(campaignsActions.campaignFailedToBeValidated({ error })),
              ),
            ),
      ),
    ),
  );

  revertToDraftCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(campaignsActions.revertToDraftCampaign),
      switchMap(({ organizationId, accountId, campaignId }) =>
        this.campaignsService
          .changeCampaignState(
            organizationId,
            accountId,
            campaignId,
            ChangeStateEnum.Revert,
          )
          .pipe(
            map(() => campaignsActions.campaignRevertedToDraftSuccessfully()),
            catchError((error) =>
              of(campaignsActions.campaignFailedToBeRevertedToDraft({ error })),
            ),
          ),
      ),
    ),
  );

  launchBat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(campaignsActions.launchBat),
      switchMap(({ organizationId, accountId, bat }) =>
        this.campaignsService.launchBat(organizationId, accountId, bat).pipe(
          map(() => campaignsActions.batLaunchedSuccessfully()),
          catchError((error) =>
            of(campaignsActions.batFailedToBeLaunched({ error })),
          ),
        ),
      ),
    ),
  );

  campaignsMetrics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(campaignsActions.getCampaignsMetrics),
      switchMap(({ organizationId, accountId, startDate, endDate }) =>
        this.campaignsService
          .getCampaignsMetrics(organizationId, accountId, startDate, endDate)
          .pipe(
            map((result: APIResponse<CampaignsMetricsOverviewModel>) =>
              campaignsActions.campaignsMetricsRetrievedSuccessfully({
                metrics: result.data as CampaignsMetricsOverviewModel,
              }),
            ),
            catchError((error) =>
              of(
                campaignsActions.campaignsMetricsFailedToBeRetrieved({
                  error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private campaignsService: CampaignsService,
  ) {}
}
